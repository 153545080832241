import { addListener } from 'utils/dom';
import ResultsFetcher from './_results-fetcher';

class UrlHandler {
  static Event = {
    UPDATED_URL: 'cf-1.url-handler.updated-url',
  };

  constructor(calendarElem) {
    this.mCalendarElem = calendarElem;
  }

  init = () => {
    this.mCalendarElem && this._events();
  };

  _events = () => {
    addListener(
      this.mCalendarElem,
      ResultsFetcher.EventOut.LOADING,
      ({ detail }) => {
        this._setHistory(detail.url);
      }
    );
  };

  _sanitizeUrl = url => {
    // For testing visit: https://regex101.com/r/YPNipu/5
    return url
      .split('?')[1] // remove everything before the query
      .replace(/tx_solr%5Bpage%5D=\d*&?|type=\d*&?/gi, '') // remove the type and page
      .replace(/&+(?!\S)/, ''); // remove any trailing '&'s
  };

  _setHistory = url => {
    const queryString = this._sanitizeUrl(url);
    // eslint-disable-next-line no-restricted-globals
    history.replaceState(
      null,
      '',
      // eslint-disable-next-line no-restricted-globals
      queryString ? `?${queryString}` : location.href.split('?')[0]
    );
  };
}

export default UrlHandler;
