import { $, $$ } from '../../../shared/js/utils/dom';
import { LoadingAnimation } from '../loading-animation/loading-animation';
import ResultsFetcher from 'modules/results-fetcher';
import TruncateSearchResults from '../teaser/list/list';

const defaultParams = {
  searchFilterElem: null,
};

class ResultsLoader {
  constructor(inParams = {}) {
    const params = { ...defaultParams, ...inParams };

    this.searchFilterElem = params.searchFilterElem;

    if (!this.searchFilterElem) return;

    this.reducersContainerElem = $(
      '.sf__reducers-container',
      this.searchFilterElem
    );

    this.resultsElem = $('.sf__results', this.searchFilterElem);

    this.loadingAnimation = null;
    this.resultsFetcher = null;
  }

  init = () => {
    if (!this.reducersContainerElem || !this.resultsElem) {
      return;
    }

    this.loadingAnimation = new LoadingAnimation(this.searchFilterElem, false, {
      position: 'absolute',
      left: 'calc(50% - 0.5em)',
      bottom: '-200px',
    });

    this.resultsFetcher = new ResultsFetcher({
      onLoaded: this._onLoaded,
      onError: error => console.error(error),
    });

    // if reducers list is not empty show it
    if ($('li', this.reducersContainerElem)) {
      this.reducersContainerElem.classList.add(
        'sf__reducers-container--visible'
      );
    }

    this.initTruncateSearchResults();

    // set load more button
    const loadMoreBtnContainerElem = $(
      '[data-container="load-more-btn"]',
      this.searchFilterElem
    );
    loadMoreBtnContainerElem && this._setLoadMoreBtn(loadMoreBtnContainerElem);
  };

  _onLoaded = data => {
    this._hideLoadingAnim();
    this._updateResultsList(data);
    this.truncate && this.truncate.loadMore();
  };

  initTruncateSearchResults = () => {
    const listItem = [
      ...$('.search-filter').querySelectorAll('.list__item__info--text'),
    ];

    if (listItem && window.innerWidth < 1024) {
      this.truncate = new TruncateSearchResults(listItem);
      this.truncate.init();
    }
  };

  _updateResultsList = htmlStr => {
    if (!htmlStr) return;

    const bodyElem = new DOMParser().parseFromString(htmlStr, 'text/html').body;
    const listElem = $('ul', bodyElem);
    const loadMoreBtnContainerElem = $(
      '[data-container="load-more-btn"]',
      bodyElem
    );

    // set results
    if (listElem) {
      this.resultsElem.insertAdjacentHTML('beforeend', listElem.innerHTML);
    }

    // set "load more button"
    if (loadMoreBtnContainerElem) {
      this._setLoadMoreBtn(loadMoreBtnContainerElem);
    }
  };

  _showLoadingAnim = () => {
    this.resultsElem.style.marginBottom = '400px';
    this.loadingAnimation.show();
  };

  _hideLoadingAnim = () => {
    this.resultsElem.style.marginBottom = '';
    this.loadingAnimation.hide();
  };

  _setLoadMoreBtn = loadMoreBtnContainerElem => {
    const that = this;
    const loadMoreBtnElem = $('button', loadMoreBtnContainerElem);

    if (!loadMoreBtnElem) return;

    this.searchFilterElem.appendChild(loadMoreBtnContainerElem);

    loadMoreBtnElem.addEventListener('click', function cb(e) {
      // event listener should be triggered once
      e.currentTarget.removeEventListener('click', cb);

      const uri = loadMoreBtnElem.dataset.uri;

      if (!uri) return;

      // remove "load more button"
      that._deleteLoadMoreBtn();
      // show the loading animation
      that._showLoadingAnim();
      // fetch data
      const url = window.location.protocol + '//' + window.location.host + uri;
      that.resultsFetcher.fetch(url);
    });
  };

  _deleteLoadMoreBtn = () => {
    if (!this.searchFilterElem) return;

    const elem = this.searchFilterElem;

    $$(':scope > [data-container="load-more-btn"]', elem).forEach(childElem => {
      elem.removeChild(childElem);
    });
  };
}

export default ResultsLoader;
