import { $, $$, addListener, dispatchEvent } from 'utils/dom';
import DragScrollSnap from 'modules/dragscroll-snap';
import ScrollToListElement from 'modules/scroll-to-list-element';

class CalendarScroller {
  static DeviceType = {
    MOBILE: 'mobile',
    DESKTOP: 'desktop',
  };

  static Event = {
    DATE_CHANGED: 'calendar-scroller.event.date-changed',
  };

  constructor(calendarElem, eventChannelElem) {
    this.mCalendarElem = calendarElem;
    this.mEventChannelElem = eventChannelElem;

    this.mDragScrollElem = $(
      ':scope > .ct-1__calendar-inner > .dragscroll',
      this.mCalendarElem
    );
    this.mNextBtnElem = $(
      ':scope > .ct-1__calendar-inner > .ct-1__calendar-btn-next',
      this.mCalendarElem
    );
    this.mPrevBtnElem = $(
      ':scope > .ct-1__calendar-inner > .ct-1__calendar-btn-prev',
      this.mCalendarElem
    );

    this.mDeviceType = undefined;
    this.mSavedPos = 0;
    this.mSelectedListItemElem = undefined;
    this.mSnapOnResizeTimeoutId = undefined;
  }

  init = () => {
    if (!this.mCalendarElem || !this.mDragScrollElem) {
      return;
    }

    this._events();

    new DragScrollSnap(this.mDragScrollElem, this.mEventChannelElem).init();
    new ScrollToListElement(
      this.mDragScrollElem,
      this.mEventChannelElem
    ).init();

    this._onResize();

    // select first item
    const firstListItemElem = $(':scope > ul > li', this.mDragScrollElem);
    this._selectListItemElem(firstListItemElem);
  };

  _getDeviceType = () => {
    if (window.innerWidth <= 1024) {
      return CalendarScroller.DeviceType.MOBILE;
    } else {
      return CalendarScroller.DeviceType.DESKTOP;
    }
  };

  _selectListItemElem = listItemElem => {
    if (this.mSelectedListItemElem === listItemElem) {
      return;
    }

    // unselect current element
    if (this.mSelectedListItemElem) {
      const selectedElem = $(
        ':scope > .ct-1__date',
        this.mSelectedListItemElem
      );
      selectedElem.classList.remove('ct-1__date--selected');
    }

    // select new element
    const newSelectedElem = $(':scope > .ct-1__date', listItemElem);
    newSelectedElem.classList.add('ct-1__date--selected');

    this.mSelectedListItemElem = listItemElem;

    dispatchEvent(this.mEventChannelElem, CalendarScroller.Event.DATE_CHANGED, {
      elem: newSelectedElem,
      date: $(':scope > time', newSelectedElem).getAttribute('datetime'),
    });

    return true;
  };

  _events = () => {
    if (!this.mEventChannelElem) {
      return;
    }

    addListener(window, 'resize', this._onResize);

    $$(':scope > ul > li', this.mDragScrollElem).forEach(listItemElem => {
      const btnElem = $('.ct-1__date', listItemElem);
      if (btnElem) {
        addListener(btnElem, 'click', () =>
          this._selectListItemElem(listItemElem)
        );
        addListener(btnElem, 'keypress', e => {
          if (e.keyCode === 32 || e.keyCode === 13) {
            this._selectListItemElem(listItemElem);
          }
        });
      }
    });

    this.mNextBtnElem &&
      addListener(this.mNextBtnElem, 'click', () => this._onBtnClick(false));

    this.mPrevBtnElem &&
      addListener(this.mPrevBtnElem, 'click', () => this._onBtnClick(true));
  };

  _onBtnClick = isPrevBtnClicked => {
    let nextElem;
    if (isPrevBtnClicked) {
      nextElem = this.mSelectedListItemElem
        ? this.mSelectedListItemElem.previousElementSibling
        : $(':scope > ul > li', this.mDragScrollElem);
    } else {
      nextElem = this.mSelectedListItemElem
        ? this.mSelectedListItemElem.nextElementSibling
        : $(':scope > ul > li', this.mDragScrollElem);
    }

    if (!nextElem || !this._selectListItemElem(nextElem)) {
      return;
    }

    dispatchEvent(
      this.mEventChannelElem,
      ScrollToListElement.EventIn.SCROLL_TO,
      { elem: nextElem }
    );
  };

  _onResize = () => {
    const deviceType = this._getDeviceType();

    this.mSnapOnResizeTimeoutId && clearTimeout(this.mSnapOnResizeTimeoutId);

    if (deviceType !== this.mDeviceType) {
      if (deviceType === CalendarScroller.DeviceType.DESKTOP) {
        this.mCalendarElem.classList.remove('ct-1__calendar--horizontal');
        this.mCalendarElem.classList.add('ct-1__calendar--vertical');
      } else {
        this.mCalendarElem.classList.remove('ct-1__calendar--vertical');
        this.mCalendarElem.classList.add('ct-1__calendar--horizontal');
      }

      this.mDeviceType = deviceType;
    }

    this._updateScrollPos();
  };

  _updateScrollPos = () => {
    const cbFunc = () => {
      dispatchEvent(
        this.mEventChannelElem,
        ScrollToListElement.EventIn.SCROLL_TO,
        { elem: this.mSelectedListItemElem }
      );

      this.mEventChannelElem.removeEventListener(
        DragScrollSnap.EventOut.DRAG_END,
        cbFunc
      );
    };

    if (this.mSnapOnResizeTimeoutId) {
      clearTimeout(this.mSnapOnResizeTimeoutId);
      this.mSnapOnResizeTimeoutId = null;

      this.mEventChannelElem.removeEventListener(
        DragScrollSnap.EventOut.DRAG_END,
        cbFunc
      );
    }

    this.mSnapOnResizeTimeoutId = setTimeout(() => {
      this.mEventChannelElem.addEventListener(
        DragScrollSnap.EventOut.DRAG_END,
        cbFunc
      );

      const deviceType = this._getDeviceType();
      if (deviceType === CalendarScroller.DeviceType.DESKTOP) {
        dispatchEvent(
          this.mEventChannelElem,
          DragScrollSnap.EventIn.SNAP_VERTICALLY
        );
      } else {
        dispatchEvent(
          this.mEventChannelElem,
          DragScrollSnap.EventIn.SNAP_HORIZONTALLY
        );
      }
      this.mSnapOnResizeTimeoutId = null;
    }, 100);
  };
}

export default CalendarScroller;
