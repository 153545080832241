import { $ } from 'utils/dom';
import Slider from 'modules/Slider/Slider';

export class MonthObjectSlider {
  constructor(elem) {
    this.mSliderElem = $(':scope > .items', elem);

    this.init();
  }

  init = () => {
    new Slider(this.mSliderElem, {
      gap: '2rem',
      config: {
        pageNumbers: true,
        pageDots: false,
        cellAlign: 'center',
        groupCells: 3,
      },
      conditions: [
        {
          breakpoint: 'less-equal 992',
          config: {
            groupCells: 1,
          },
        },
        {
          breakpoint: 'less-equal 1380',
          config: {
            groupCells: 2,
          },
        },
        {
          breakpoint: 'more 1380',
          config: {
            groupCells: 3,
          },
        },
      ],
    }).init();
  };
}
