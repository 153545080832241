import { $, $$ } from 'utils/dom';

// eslint-disable-next-line import/no-anonymous-default-export
export default class {
  constructor(el, options) {
    this.el = el;
    this.options = options;

    this.isAnimating = false;

    this.items = $$(this.options.itemSelector).map(itemEl => ({
      el: itemEl,
      toggle: $(this.options.toggleSelector, itemEl),
      body: $(this.options.bodySelector, itemEl),
    }));

    this.items.forEach(item => {
      if (item.toggle === null) return;

      item.body.classList.add('DOM-slider-hidden');

      item.toggle.addEventListener('click', event => {
        event.preventDefault();
        this.toggle(item);
      });
    });
  }

  toggle(item) {
    if (this.isAnimating) return;

    const otherItems = this.items.filter(_item => _item !== item);
    otherItems.forEach(otherItem => {
      otherItem.el.classList.remove('is-active');
      if (otherItem.body === null || otherItem.toggle === null) return;

      otherItem.body.slideUp(400);
      otherItem.toggle && otherItem.toggle.classList.remove('is-toggled');
    });

    item.el.classList.toggle('is-active');
    this.isAnimating = true;
    item.body.slideToggle(400).then(() => {
      this.isAnimating = false;
    });
    item.toggle.classList.toggle('is-toggled');
  }
}
