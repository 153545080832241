import { $, $$ } from 'utils/dom';

// this class is initialized in frontend/src/components/elements/search-filter/_results-loader.js
class TruncateSearchResults {
  BREAKPOINT = 1024;

  constructor(listItem) {
    this.listItemInfoText = listItem; // this.listItemInfoText = array of DOM nodes width class .list__item__info--text that contain the text to be truncated
    this.originalText = []; // this.originalText = array will contain the original non-truncated texts (so it can be restored on resize) => should be in sync with this.listItemInfoText
    this.mediaQuery = window.matchMedia(`(max-width: ${this.BREAKPOINT}px)`);
  }

  init() {
    // save original non-truncated text
    this.listItemInfoText.forEach(item => {
      this.originalText.push(item.textContent);
    });
    this.bindEvents();
    if (window.innerWidth < 1024) {
      this.truncate(this.listItemInfoText);
    }
  }

  bindEvents() {
    this.mediaQuery.addListener(e => this.onResize(e));
  }

  truncate(items) {
    items.forEach(item => {
      if (item.innerHTML.length > 150) {
        item.innerHTML = item.innerHTML.substring(0, 150) + '...';
      }
    });
  }

  onResize(e) {
    // if viewport is resized > 1024px: replace the truncated strings by the original, previously saved text
    if (!this.mediaQuery.matches) {
      this.listItemInfoText.forEach((item, index) => {
        item.textContent = this.originalText[index];
      });
    } else {
      // if viewport is resized < 1024px: truncate the text
      this.truncate(this.listItemInfoText);
    }
  }

  loadMore() {
    // loadMore is triggered after results are loaded (see frontend/src/components/elements/search-filter/_results-loader.js)
    // 1) fetching the new content: the last 10 list__items
    const lastTen = $$('.search-filter .list__item').slice(-10);
    const lastTenText = [];
    // 2) for each new list__item:
    // a) find the text paragraph (that should be truncated) and
    // b) if it exists: push it to the array originalTexts
    // c) push it to the array lastTenText
    lastTen.forEach(item => {
      const text = $('.list__item__info--text', item);
      text && this.originalText.push(text.textContent);
      text && lastTenText.push(text);
    });

    // merge arrays with old and newly loaded Items together ... and truncate everything
    this.listItemInfoText = [...this.listItemInfoText, ...lastTenText];
    this.truncate(this.listItemInfoText);
  }
}

export default TruncateSearchResults;
