import { addListener, $ } from 'utils/dom';
import DateGroup from '../_date-group/date-group';
import UsedFilters from './used-filters';
import * as Translation from 'utils/translation';

const defaultOpt = {
  dateFromElem: null,
  dateToElem: null,
  eventScopeElem: document.documentElement,
};

class UsedFilterDate {
  static Event = {
    IS_REMOVED: 'cf-1.used-filter-date.is-removed',
  };

  constructor(options) {
    const opt = Object.assign(defaultOpt, options);

    this.mEventScopeElem = opt.eventScopeElem;
    this.mDateFromElem = opt.dateFromElem;
    this.mDateToElem = opt.dateToElem;

    this.mDateFromValue = '';
    this.mDateToValue = '';

    this.mFilterElem = this._createFilter();
    this.mFilterBtnElem = $('button', this.mFilterElem);

    this.mEventScopeElem && this._events();
  }

  getElem = () => {
    return this.mFilterElem;
  };

  _events = () => {
    // remove used filter by clicking on it
    addListener(this.mFilterBtnElem, 'click', this._removeFilter);

    // remove used filter by receiving the event REMOVE_ALL
    addListener(
      this.mEventScopeElem,
      UsedFilters.Event.REMOVE_ALL,
      this._removeFilter
    );

    // remove used filter by receiving the event CHANGED with empty parameter values
    addListener(this.mEventScopeElem, DateGroup.Event.IS_CHANGED, e => {
      const dateFromValue = e.detail.dateFromElem.value;
      const dateToValue = e.detail.dateToElem.value;

      !dateFromValue && !dateToValue && this._removeFilter();
    });
  };

  _removeFilter = () => {
    if (!this.mFilterElem) return;

    // remove filter
    const listElem = this.mFilterElem.parentNode;
    listElem && listElem.removeChild(this.mFilterElem);
    this.mFilterElem = null;

    // send custom event to uncheck the checkbox
    const event = new CustomEvent(UsedFilterDate.Event.IS_REMOVED);
    this.mEventScopeElem.dispatchEvent(event);
  };

  _createFilter = () => {
    const dateCaption = Translation.getKey(
      'calendar-filter',
      'used-filter-date-caption'
    );
    const newFilter = document.createElement('li');
    newFilter.setAttribute('data-js', 'date-filter');
    newFilter.innerHTML = `<button type="button">${dateCaption} <span class="icon-x"></button>`;

    return newFilter;
  };
}

export default UsedFilterDate;
