function toTwoDigits(inDigit) {
  return ('0' + inDigit).slice(-2);
}

export const strToDate = date => {
  let retDate = null;

  if (typeof date === 'string') {
    retDate = new Date();
    const dateArr = date.split('.');

    if (dateArr.length !== 3) {
      return retDate;
    }

    const day = parseInt(dateArr[0]);
    const month = parseInt(dateArr[1]);
    const year = Math.min(parseInt(dateArr[2]), 2037); // https://en.wikipedia.org/wiki/Year_2038_problem

    if (isNaN(day) || isNaN(month) || isNaN(year)) {
      return retDate;
    }

    retDate.setDate(day);
    retDate.setMonth(month - 1);
    const fullYear = `${year}`.length < 4 ? 2000 + year : year;
    retDate.setFullYear(fullYear);
  }

  return retDate;
};

//
export const DateToFormatedStr = inDate => {
  if (inDate == null) return inDate;

  const year = Math.min(inDate.getFullYear(), 2037); // https://en.wikipedia.org/wiki/Year_2038_problem
  const month = toTwoDigits(inDate.getMonth() + 1);
  const date = toTwoDigits(inDate.getDate());
  const hours = toTwoDigits(inDate.getHours());
  const minutes = toTwoDigits(inDate.getMinutes());

  return `${year}${month}${date}${hours}${minutes}`;
};
