const easing = {
  LINEAR: t => {
    return t;
  },
  EASE_IN_QUAD: t => {
    return t * t;
  },
  EASE_OUT_QUAD: t => {
    return t * (2 - t);
  },
  EASE_IN_OUT_QUAD: t => {
    return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
  },
  EASE_IN_CUBIC: t => {
    return t * t * t;
  },
  EASE_OUT_CUBIC: t => {
    return --t * t * t + 1;
  },
  EASE_IN_OUT_CUBIC: t => {
    return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
  },
  EASE_IN_QUART: t => {
    return t * t * t * t;
  },
  EASE_OUT_QUART: t => {
    return 1 - --t * t * t * t;
  },
  EASE_IN_OUT_QUART: t => {
    return t < 0.5 ? 8 * t * t * t * t : 1 - 8 * --t * t * t * t;
  },
  EASE_IN_QUINT: t => {
    return t * t * t * t * t;
  },
  EASE_OUT_QUINT: t => {
    return 1 + --t * t * t * t * t;
  },
  EASE_IN_OUT_QUINT: t => {
    return t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * --t * t * t * t * t;
  },
};

const defaultOptions = {
  elem: window,
  easing: easing.LINEAR,
  onAnimationEnd: undefined,
  onAnimationCanceled: undefined,
};

class AniScroll {
  static Easing = { ...easing };

  constructor(opt) {
    const options = { ...defaultOptions, ...opt };

    this.mElem = options.elem;
    this.mEasing = options.easing;
    this.mOnAnimationEndCb = options.onAnimationEnd;
    this.mOnAnimationCanceledCb = options.onAnimationCanceled;

    this.mStartScrollTop = undefined;
    this.mStartScrollLeft = undefined;
    this.mStartTime = undefined;

    this.mCancelObject = null;
  }

  scroll = (scrollLeft, scrollTop, duration = 500) => {
    this.cancel();

    this.mStartScrollTop =
      this.mElem === window ? window.pageYOffset : this.mElem.scrollTop;
    this.mStartScrollLeft =
      this.mElem === window ? window.pageXOffset : this.mElem.scrollLeft;

    this.mStartTime = this._now();

    if ('requestAnimationFrame' in window === false) {
      this.mElem.scrollTop = scrollTop;
      this.mElem.scrollLeft = scrollLeft;
      this._onAnimationEnd();

      return;
    }

    this._aniscroll(scrollLeft, scrollTop, duration);
  };

  cancel = () => {
    if (this.mCancelObject) {
      this.mCancelObject.canceled = true;
    }
  };

  isRunning = () => {
    return this.mCancelObject !== null;
  };

  _now = () => {
    return 'now' in window.performance
      ? window.performance.now()
      : new Date().getTime();
  };

  _aniscroll = (inScrollLeft, inScrollTop, inDuration) => {
    const now = this._now();
    const time = Math.min(1, (now - this.mStartTime) / inDuration);
    const easingTime = this.mEasing(time);

    const currentScrollTop = Math.ceil(
      easingTime * (inScrollTop - this.mStartScrollTop) + this.mStartScrollTop
    );

    const currentScrollLeft = Math.ceil(
      easingTime * (inScrollLeft - this.mStartScrollLeft) +
        this.mStartScrollLeft
    );

    if (this.mElem === window) {
      window.scroll(currentScrollLeft, currentScrollTop);
    } else {
      this.mElem.scrollTop = currentScrollTop;
      this.mElem.scrollLeft = currentScrollLeft;
    }

    if (
      (this.mElem.scrollTop === inScrollTop &&
        this.mElem.scrollLeft === inScrollLeft) ||
      easingTime === 1
    ) {
      this._onAnimationEnd();
      return;
    }

    const cancelObject = { canceled: false };
    this.mCancelObject = cancelObject;

    window.requestAnimationFrame(() => {
      if (!cancelObject.canceled) {
        this._aniscroll(inScrollLeft, inScrollTop, inDuration);
      } else {
        this._onAnimationCanceled();
      }
    });
  };

  _onAnimationEnd = () => {
    this.mCancelObject = null;
    this.mOnAnimationEndCb && this.mOnAnimationEndCb();
  };

  _onAnimationCanceled = () => {
    this.mCancelObject = null;
    this.mOnAnimationCanceledCb && this.mOnAnimationCanceledCb();
  };
}

export default AniScroll;
