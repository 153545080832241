import { $, $$ } from 'utils/dom';
import { breakpoints } from 'utils/breakpoints';
import hoverintent from 'hoverintent';

export class Menu {
  constructor(el) {
    this.el = el;
    this.header = $('.header');
    this.items = $$('.menu > ul > li');
    this.highlight = $('.menu-highlight');
    this.highlight.style.left = `${this.items[0].offsetLeft}px`;

    const opts = {
      sensitivity: 2,
      interval: 50,
    };
    this.items.forEach(item => {
      hoverintent(
        item,
        () => {
          this.onMouseEnterItem(item);
        },
        () => {}
      ).options(opts);
    });
    hoverintent(
      this.el,
      this.onMouseEnter.bind(this),
      this.onMouseLeave.bind(this)
    ).options(opts);
    this.closeByEsc();
  }

  onMouseEnterItem(hoveredItem) {
    if (breakpoints.isMobileMenuMax) return;

    this.items
      .filter(item => item !== hoveredItem)
      .forEach(item => {
        item.classList.remove('is-active');
      });
    hoveredItem.classList.add('is-active');
    this.highlight.style.left = `${hoveredItem.offsetLeft}px`;
    this.highlight.style.width = `${hoveredItem.children[0].offsetWidth}px`;
    this.header.classList.add('is-menu-active');
  }

  onMouseEnter() {
    if (breakpoints.isMobileMenuMax) return;

    this.highlight.style.opacity = 1;
    this.menuIsOpen = true;
  }

  onMouseLeave() {
    if (breakpoints.isMobileMenuMax) return;

    this.highlight.style.opacity = 0;
    this.header.classList.remove('is-menu-active');
    this.items.forEach(item => {
      item.classList.remove('is-active');
    });
  }

  closeByEsc() {
    document.addEventListener('keydown', e => {
      if (e.key === 'Escape' && this.menuIsOpen === true) {
        this.highlight.style.opacity = 0;
        this.header.classList.remove('is-menu-active');
        this.items.forEach(item => {
          item.classList.remove('is-active');
        });
      }
    });
  }
}
