import { $, $$, closest } from 'utils/dom';
import EventListener from 'utils/event-listener';
import Lightbox from 'modules/Lightbox/Lightbox';
import Slider from 'modules/Slider/Slider';
import ShowMore from 'modules/show-more';
import imagesLoaded from 'imagesloaded';

const $$sliderGalleries = $$('div.gallery[data-type="slider"]');
if ($$sliderGalleries.length) {
  $$sliderGalleries.forEach($gallery => {
    const $target = $('.items', $gallery);
    const theme = $gallery.classList.contains('gallery--spectrum')
      ? 'spectrum'
      : 'tm';

    const lightbox = new Lightbox($gallery, {
      selector: '.lightbox-toggle',
      theme,
    });

    const SliderBox = new Slider(
      $target,
      {
        config: {
          pageNumbers: true,
          pageDots: false,
        },
        conditions: [
          {
            breakpoint: 'less-equal 600',
            config: {
              cellAlign: 'left',
              groupCells: 1,
            },
          },
          {
            breakpoint: 'less-equal 768',
            config: {
              cellAlign: 'center',
              wrapAround: true,
              contain: false,
              groupCells: false,
            },
          },
          {
            breakpoint: 'less-equal 1024',
            config: {
              cellAlign: 'center',
              wrapAround: true,
              contain: false,
              groupCells: false,
            },
          },
          {
            breakpoint: 'more 1024',
            config: {
              cellAlign: 'center',
              wrapAround: true,
              contain: false,
              groupCells: false,
            },
          },
        ],
      },
      lightbox
    );

    SliderBox.init({
      on: {
        ready: () => {
          new EventListener('resize orientationchange', null, () =>
            SliderBox.slider.resize()
          ).listen();
        },
      },
    });
  });
}

const $$gridGalleries = $$('div.gallery[data-type="grid"]');
if ($$gridGalleries.length) {
  $$gridGalleries.forEach($gallery => {
    const $btnContainer = closest($gallery, '.container');
    const theme = $gallery.classList.contains('gallery--spectrum')
      ? 'spectrum'
      : 'tm';
    new Lightbox($gallery, {
      selector: '.lightbox-toggle',
      theme,
    }).init();

    new ShowMore({
      container: $gallery,
      btnContainer: $btnContainer,
      items: $$('.item', $gallery),
      loadMoreBtn: $('.load-more-button', $btnContainer),
      initialImages: 6,
      onClickImages: 3,
    }).init();
  });
}

const $$newsGridGalleries = $$('div.gallery[data-type="news-grid"]');
if ($$newsGridGalleries.length) {
  import(/* webpackChunkName: 'masonry-layout' */ 'masonry-layout').then(_ => {
    const Masonry = _.default;

    $$newsGridGalleries.forEach($gallery => {
      const $items = $('.items', $gallery);

      // add lightbox to each item
      const theme = $gallery.classList.contains('gallery--spectrum')
        ? 'spectrum'
        : 'tm';
      new Lightbox($items, {
        selector: '.lightbox-toggle',
        theme,
      }).init();

      // create masonry gallery after all images are loaded
      imagesLoaded($items, () => {
        const msnry = new Masonry($items, {
          itemSelector: '.item',
          columnWidth: '.item:first-child',
          percentPosition: true,
        });

        msnry.layout();
      });
    });
  });
}
