import { $, $$, addListener } from 'utils/dom';
import { APILazerLoader } from 'utils/lazer-loader';
import { getApi, Plyr } from '../../../../shared/js/modules/Player/create-plyr';

const createPlayer = async (api, $wrapper) => {
  const $player = $('.plyr-player', $wrapper);
  const pl = new Plyr(api, $player, {});

  const player = await pl.create();

  // don't close controls if <figcaption> is pointed
  const $figcaption = $('figcaption', $wrapper);
  let keepControlsOpen = false;
  if ($figcaption) {
    addListener($figcaption, 'mouseenter mouseleave', e => {
      if (e.type === 'mouseenter') {
        keepControlsOpen = true;
      } else if (e.type === 'mouseleave') {
        keepControlsOpen = false;
      }
    });

    const observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        const $elem = mutation.target;
        const isControlsClosed = $elem.classList.contains(
          'plyr--hide-controls'
        );

        if (isControlsClosed && keepControlsOpen) {
          player.toggleControls(true);
        }
      });
    });

    observer.observe(player.elements.container, {
      attributes: true,
      subtree: false,
      childList: false,
      characterData: false,
      attributeFilter: ['class'],
    });
  }

  // set event handler to add/remove style classes
  player.on('ready', event => {
    $wrapper.classList.add('is-ready');
  });
  player.on('controlshidden', event => {
    $wrapper.classList.add('controls-hidden');
  });
  player.on('controlsshown', event => {
    $wrapper.classList.remove('controls-hidden');
  });
};

export const observe = targets => {
  new APILazerLoader({
    targets,
    config: {
      rootMargin: '50px 0px',
      threshold: 0.01,
    },
    cb: (api, { target }) => createPlayer(api, target),
    loadAPI: () => getApi(),
  }).init();
};

const $$wrappers = $$('.player-wrapper');
$$wrappers.length && observe($$wrappers);
