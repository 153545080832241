import { $$ } from 'utils/dom';
import { VideoLazerLoader } from 'utils/lazer-loader';

const onPlaying = video => {
  let isPlaying = true;
  const $btn = video.nextElementSibling;
  $btn.classList.add('is-playing');
  $btn.addEventListener('click', () => {
    if (isPlaying) {
      $btn.classList.add('is-paused');
      $btn.classList.remove('is-playing');
      video.pause();
    } else {
      $btn.classList.add('is-playing');
      $btn.classList.remove('is-paused');
      video.play();
    }
    isPlaying = !isPlaying;
  });
};

const observe = targets => {
  new VideoLazerLoader({
    targets,
    config: {
      rootMargin: '50px 0px',
      threshold: 0.01,
    },
    cb: ({ target: video }) => onPlaying(video),
  }).init();
};

const $$videos = $$('.cinemagraph-wrapper video');
$$videos.length && observe($$videos);
