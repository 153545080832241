import { $, addListener } from 'utils/dom';
import Drawer from 'modules/Drawer/Drawer';

class ReducersDrawer {
  static DeviceType = {
    MOBILE: 'mobile',
    DESKTOP: 'desktop',
  };

  constructor(containerElem, btnElem) {
    this.containerElem = containerElem;
    this.btnElem = btnElem;
    this.closeIconElem =
      this.btnElem && $('.sf__reducers-close-icon', this.btnElem);

    this.drawer = null;
  }

  init = () => {
    if (!this.containerElem || !this.btnElem) return;

    this.drawer = new Drawer({
      containerElem: this.containerElem,
      transitionSpeed: '500ms',
    });

    this._events();

    this._onResize();
  };

  _events = () => {
    addListener(window, 'resize', this._onResize);
    addListener(this.btnElem, 'click', e => {
      e.preventDefault();
      this.drawer && this.drawer.toggle();

      this._updateReducersCloseIcon();
    });
  };

  _onResize = () => {
    const deviceType = this._getDeviceType();

    if (this.currentDeviceType === deviceType) return;

    if (deviceType === ReducersDrawer.DeviceType.MOBILE) {
      this.drawer && this.drawer.destroy();
      this.drawer = new Drawer(this.drawerContainerElem);
      this._updateReducersCloseIcon();
      this.btnElem.removeAttribute('disabled');
    } else if (deviceType === ReducersDrawer.DeviceType.DESKTOP) {
      this.drawer && this.drawer.destroy();
      this.drawer = null;
      this.btnElem.setAttribute('disabled', '');
    }

    this.currentDeviceType = deviceType;
  };

  _getDeviceType = () => {
    const width = window.innerWidth;

    if (width > 600) {
      return ReducersDrawer.DeviceType.DESKTOP;
    } else {
      return ReducersDrawer.DeviceType.MOBILE;
    }
  };

  _updateReducersCloseIcon = () => {
    if (!this.closeIconElem) return;

    if (this.drawer && this.drawer.isOpen()) {
      this.closeIconElem.classList.remove('icon-plus');
      this.closeIconElem.classList.add('icon-minus');
    } else {
      this.closeIconElem.classList.remove('icon-minus');
      this.closeIconElem.classList.add('icon-plus');
    }
  };
}

export default ReducersDrawer;
