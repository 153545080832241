const docLang = document.documentElement.lang;
const lang = docLang.length > 2 ? docLang.substring(0, 2) : docLang;
const labels = {
  prev: {
    de: 'Vorherigen Inhalt anzeigen',
    en: 'show previous slide',
  },
  next: {
    de: 'Nächsten Inhalt anzeigen',
    en: 'show next slide',
  },
  page: {
    de: 'Springe zum Inhalt',
    en: 'jump to content',
  },
  current: {
    de: '(Aktueller Inhalt)',
    en: '(active slide)',
  },
  skipNext: {
    de: 'Sliderpunkte überspringen und zur nächster Sprungmarke springen',
    en: 'skip bullets and jump to next skiplink',
  },
  skipPrev: {
    de: 'Sliderpunkte überspringen und direkt zum Slider springen',
    en: 'skip bullets and jump directly to the slider',
  },
  skipSliderForwards: {
    de: 'Slider überspringen und zur nächsten Sprungmarke springen',
    en: 'skip slider and jump to next skiplink',
  },
  skipSliderBackwards: {
    de: 'Slider überspringen und zur vorgelagerten Sprungmarke springen',
    en: 'skip slider and jump to preceding skiplink',
  },
  playPause: {
    de: 'Slider starten/stoppen',
    en: 'start/stop slider',
  },
};

export const glideLang = lang;
export const glideLabels = labels;

export const glideControls = sliderID => `
    <div class="glide__controls" data-glide-el="controls">
        <button
          class="glide__arrow glide__arrow--left has-tooltip"
          aria-label="${labels.prev[lang]}"
          aria-controls="${sliderID}"
          data-glide-dir="<"
          >
          <span class="icon icon--arrow-slider-left" aria-hidden="true"></span>
          <span class="sr-only">${labels.prev[lang]}</span>
        </button>
        <button
          class="glide__arrow glide__arrow--right has-tooltip"
          aria-label="${labels.next[lang]}"
          aria-controls="${sliderID}"
          data-glide-dir=">"
          >
          <span class="icon icon--arrow-slider-right" aria-hidden="true"></span>
          <span class="sr-only">${labels.next[lang]}</span>
        </button>
    </div>
`;

export const generateBullets = (items, sliderID) => {
  return `
  <a class="glide__skip-link" data-target="next">${labels.skipNext[lang]}</a>
    <ul class="glide__bullets">
    ${items
      .map((_, index) => {
        return `<li><button aria-label="${labels.page[lang]} ${
          index + 1
        }" aria-controls="${sliderID}" class="glide__bullet has-tooltip has-tooltip--top" data-glide-dir="=${index}"><span class="sr-only">${
          labels.page[lang]
        } ${
          index + 1
        }</span><span class="is-hidden glide__bullet-current-text">${
          labels.current[lang]
        }</span></button></li>`;
      })
      .join('')}
    </ul>
    <a class="glide__skip-link" data-target="slide">${labels.skipPrev[lang]}</a>
`;
};

export const generateNumeric = slidesLength => {
  return `
    <div class="glide__numeric">
        <p class="glide__numeric-text" aria-live="polite">
          <span class="glide__numeric-description" >Inhalt</span>
          <span class="glide__numeric-index" >1</span><span aria-hidden="true"></span><span class="sr-only">von</span>/${slidesLength}</p>
    </div>
`;
};

export const generateSliderSkipForwardsLink = sliderID => {
  return `
  <a class="glide__skip-link skip-link" data-target="end" id="${sliderID}-skiplink-start" tabindex="0">${labels.skipSliderForwards[lang]}</a>
`;
};

export const generateSliderSkipBackwardsLink = sliderID => {
  return `
  <a class="glide__skip-link glide__skip-link--is-last skip-link" data-target="start" id="${sliderID}-skiplink-end" tabindex="0">${labels.skipSliderBackwards[lang]}</a>
`;
};

export const generatePlayPause = sliderID => {
  return `
  <button class="glide__play has-tooltip has-tooltip--top" aria-controls="${sliderID}"  aria-label="${labels.playPause[lang]}">
    <span class="is-play ">
        <span class="sr-only">Abspielen</span>
        <i class="icon-play" aria-hidden="true"></i>
    </span>
    <span class="is-pause">
        <span class="sr-only">Pause</span>
        <i class="icon-pause" aria-hidden="true"></i>
    </span>
</button>
`;
};
