// copied from *** https://stackoverflow.com/questions/27558996/how-can-i-test-for-clip-path-support#30041538 ***
/* eslint-disable */
var areClipPathShapesSupported = function () {

  var base = 'clipPath',
      prefixes = [ 'webkit', 'moz', 'ms', 'o' ],
      properties = [ base ],
      testElement = document.createElement( 'testelement' ),
      attribute = 'polygon(50% 0%, 0% 100%, 100% 100%)';

  // Try using window.CSS.supports
  if ( 'CSS' in window && 'supports' in window.CSS ) {
    var prefixes = prefixes.map(prefix => `-${prefix}-`);
    prefixes.push('');
    for (var i = 0; i < prefixes.length; i++) {
      var prefix = prefixes[i];
      var prop = `${prefix}clip-path`;

      if ( window.CSS.supports(prop, attribute) ) { return true; }
    }
    return false;
  }

  // Push the prefixed properties into the array of properties.
  for ( var i = 0, l = prefixes.length; i < l; i++ ) {
      var prefixedProperty = prefixes[i] + base.charAt( 0 ).toUpperCase() + base.slice( 1 ); // remember to capitalize!
      properties.push( prefixedProperty );
  }

  // Interate over the properties and see if they pass two tests.
  for ( var i = 0, l = properties.length; i < l; i++ ) {
      var property = properties[i];

      // First, they need to even support clip-path (IE <= 11 does not)...
      if ( testElement.style[property] === '' ) {

          // Second, we need to see what happens when we try to create a CSS shape...
          testElement.style[property] = attribute;
          if ( testElement.style[property] !== '' ) {
              return true;
          }
      }
  }

  return false;
};

/* eslint-enable */

const isPositionStickySupported = function () {
  const posProps = ['sticky', '-webkit-sticky', '-ms-sticky'];

  const el = document.createElement('a');
  const mStyle = el.style;
  mStyle.cssText = posProps.map(prop => `position: ${prop};`).join(' ');

  return posProps.includes(mStyle.position);
};

export { areClipPathShapesSupported, isPositionStickySupported };
