import { $, $$, addListener } from 'utils/dom';
import Drawer from 'modules/Drawer/Drawer';
import DropdownMenu from './_dropdown-menu/dropdown-menu';
import CheckboxGroup from './_checkbox-group/checkbox-group';
import DateGroup from './_date-group/date-group';
import DatePicker from './_datepicker/datepicker';
import UsedFilters from './_used-filters/used-filters';
import MainController from './_main-controller';
import FiltersChangeNotifier from './_filters-change-notifier';

export class CalendarFilter {
  static DeviceType = {
    MOBILE: 'mobile',
    DESKTOP: 'desktop',
  };

  static Event = {
    DEVICE_TYPE_CHANGED: 'cf-1.device-type-changed',
  };

  static EventIn = {
    UPDATE_RESULTS_INFO: 'cf-1.update-results-info',
  };

  constructor(calendarFilterElem) {
    this.mCalendarFilterElem = calendarFilterElem;
    this.mFilterCaptionElem =
      this.mCalendarFilterElem && $('.cf-1__caption', this.mCalendarFilterElem);
    this.mFilterCaptionBtnElem =
      this.mFilterCaptionElem && $('button', this.mFilterCaptionElem);
    this.mDrawerElem =
      this.mCalendarFilterElem && $('.cf-1__drawer', this.mCalendarFilterElem);
    this.mDrawer = null;
    this.mDeviceType = null;

    this._initFilterChangeNotifier();
    this._initDropdownMenu();
    this._initCheckboxes();
    this._initDateFields();
    this._initUsedFilters();
    this._initDatePickers();
    this._initMainController();

    this._events();

    this._onResize();
  }

  _events = () => {
    if (!this.mFilterCaptionElem) return;

    addListener(this.mFilterCaptionElem, 'click', this._onFilterCaptionClick);
    addListener(window, 'resize', this._onResize);
    addListener(
      this.mCalendarFilterElem,
      CalendarFilter.EventIn.UPDATE_RESULTS_INFO,
      e => {
        const resultsInfoElem = $(
          '.cf-1__results-info',
          this.mCalendarFilterElem
        );
        resultsInfoElem && (resultsInfoElem.innerText = e.detail);
      }
    );
  };

  _onFilterCaptionClick = () => {
    if (!this.mDrawer) {
      return;
    }

    if (this.mDrawer.isOpen()) {
      this.mDrawer.close();
      this.mFilterCaptionElem.classList.remove('cf-1--opened');
    } else {
      this.mDrawer.open();
      this.mFilterCaptionElem.classList.add('cf-1--opened');
    }
  };

  _onResize = () => {
    const newDeviceType = this._getDeviceType();

    // eslint-disable-next-line default-case
    switch (newDeviceType) {
      case CalendarFilter.DeviceType.DESKTOP:
        if (this.mDrawer) {
          this.mDrawer.destroy();
          this.mDrawer = null;
        }

        this.mFilterCaptionBtnElem &&
          this.mFilterCaptionBtnElem.setAttribute('disabled', '');
        break;

      case CalendarFilter.DeviceType.MOBILE:
        if (!this.mDrawer) {
          this.mDrawer = new Drawer({
            containerElem: this.mDrawerElem,
            transitionSpeed: '500ms',
          });

          this.mFilterCaptionElem.classList.contains('cf-1--opened') &&
            this.mDrawer.open();

          this.mFilterCaptionBtnElem &&
            this.mFilterCaptionBtnElem.removeAttribute('disabled');
        }
        break;
    }

    // send the event DEVICE_TYPE_CHANGED
    if (newDeviceType !== this.mDeviceType) {
      this.mDeviceType = newDeviceType;
      const event = new CustomEvent(CalendarFilter.Event.DEVICE_TYPE_CHANGED, {
        detail: {
          deviceType: this.mDeviceType,
        },
      });
      this.mCalendarFilterElem.dispatchEvent(event);
    }
  };

  _getDeviceType = () => {
    const width = this.mCalendarFilterElem.offsetWidth;

    if (width > 600) {
      return CalendarFilter.DeviceType.DESKTOP;
    } else {
      return CalendarFilter.DeviceType.MOBILE;
    }
  };

  _initDropdownMenu = () => {
    $$('.cf-1__filter.ddm-1', this.mCalendarFilterElem).forEach(
      dropdownMenuElem =>
        new DropdownMenu({
          elem: dropdownMenuElem,
          eventScopeElem: this.mCalendarFilterElem,
        })
    );
  };

  _initCheckboxes = () => {
    $$('.cf-1__checkbox-group', this.mCalendarFilterElem).forEach(
      checkboxGroup =>
        new CheckboxGroup({
          checkboxGroupElem: checkboxGroup,
          eventScopeElem: this.mCalendarFilterElem,
        })
    );
  };

  _initDateFields = () => {
    const dateGroupsElem = $$('.cf-1__date-group', this.calendarFilterElem);
    dateGroupsElem.length === 2 &&
      new DateGroup({
        dateGroupFromElem: dateGroupsElem[0],
        dateGroupToElem: dateGroupsElem[1],
        eventScopeElem: this.mCalendarFilterElem,
      });
  };

  _initUsedFilters = () => {
    const usedFiltersElem = $('.cf-1__used-filters', this.mCalendarFilterElem);
    (() =>
      new UsedFilters({
        usedFiltersElem,
        eventScopeElem: this.mCalendarFilterElem,
      }))();
  };

  _initDatePickers = () => {
    $$('.cf-1__date-group', this.mCalendarFilterElem).forEach(
      dateGroupElem =>
        new DatePicker({
          dateGroupElem,
          eventScopeElem: this.mCalendarFilterElem,
        })
    );
  };

  _initMainController = () => {
    (() => new MainController(this.mCalendarFilterElem))();
  };

  _initFilterChangeNotifier = () => {
    new FiltersChangeNotifier(this.mCalendarFilterElem).init();
  };
}

// export default CalendarFilter;
