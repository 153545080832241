export class SlideInHeading {
  constructor(el) {
    this.el = el;

    this.visibilityObserver();
  }

  visibilityObserver() {
    const observer = new IntersectionObserver(
      changes => {
        if (changes[0].isIntersecting) {
          this.el.classList.add('is-triggered');
          observer.unobserve(this.el);
        }
      },
      {
        threshold: 0.5,
      }
    );
    observer.observe(this.el);
  }
}
