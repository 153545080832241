import { $ } from 'utils/dom';
import CalendarLoader from './_calendar-loader';
import ResultsLoader from './_results-loader';

export class CalendarTeaser {
  constructor(calendarTeaserElem) {
    this.mCalendarTeaserElem = calendarTeaserElem;
    this.mCalendarElem = $('.ct-1__calendar', this.mCalendarTeaserElem);
    this.mHitListElem = $('.ct-1__hit-list', this.mCalendarTeaserElem);
    this.mIsSpectrum =
      this.mCalendarTeaserElem &&
      this.mCalendarTeaserElem.classList.contains('calendar-teaser--spectrum');

    this.mResultsCache = {};

    this.init();
  }

  init = () => {
    if (
      !this.mCalendarTeaserElem ||
      !this.mCalendarElem ||
      !this.mHitListElem
    ) {
      return;
    }

    new ResultsLoader({
      hitListElem: this.mHitListElem,
      eventChannelElem: this.mCalendarTeaserElem,
      resultsCache: this.mResultsCache,
      isSpectrum: this.mIsSpectrum,
    }).init();

    new CalendarLoader({
      calendarTeaserElem: this.mCalendarTeaserElem,
      calendarElem: this.mCalendarElem,
      eventChannelElem: this.mCalendarTeaserElem,
      dataEndpoint: this.mCalendarTeaserElem.dataset.endpoint,
      resultsCache: this.mResultsCache,
      isSpectrum: this.mIsSpectrum,
    }).init();
  };
}
