import { $ } from 'utils/dom';
import ResultsFetcher from './_results-fetcher';
import ResultsLoader from './_results-loader';
import UrlHandler from './_url-handler';

export class Calendar {
  constructor(calendarElem) {
    this.mCalendarElem = calendarElem;
    this.mHighlightsElem = null;

    this._init();
  }

  _init = () => {
    if (!this.mCalendarElem) return;

    this.mHighlightsElem = $(
      'section.teaser-highlights-slider, section.container.teaser:not(.calendar__results)',
      this.mCalendarElem
    );

    new ResultsFetcher(this.mCalendarElem).init();
    new ResultsLoader(this.mCalendarElem).init();
    new UrlHandler(this.mCalendarElem).init();

    this._events();
  };

  _events = () => {
    if (!this.mHighlightsElem) return;

    this.mCalendarElem.addEventListener(ResultsFetcher.EventOut.LOADING, e => {
      const filters = e.detail && e.detail.filters;
      this.mHighlightsElem.style.display =
        !filters || filters.length ? 'none' : '';
    });
  };
}

// export default Calendar;
