import { $, $$ } from 'utils/dom';
import Slider from 'modules/Slider/Slider';

export class TeaserGrid {
  constructor(el) {
    this.el = el;
    this.itemsContainer = $('.items', this.el);
    this.items = $$('.item', this.itemsContainer);
    this.breakpoint = el.getAttribute('data-flickity-breakpoint') || '1024';
    this.gap = el.getAttribute('data-flickity-gap') || '0px';

    this.initSlider();
  }

  initSlider() {
    if (this.items.length < 3) return;

    this.slider = new Slider(this.itemsContainer, {
      config: {
        prevNextButtons: true,
        pageDots: false,
        pageNumbers: true,
      },
      conditions: [
        {
          breakpoint: `less ${this.breakpoint}`,
          isSlider: true,
          gap: this.gap,
        },
        {
          breakpoint: `more ${this.breakpoint}`,
          isSlider: false,
        },
      ],
    });
    this.slider.init();
  }
}
