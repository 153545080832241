import dateAndTime from 'date-and-time';
import { $ } from 'utils/dom';
import CalendarScroller from './_calendar-scroller';
import ResultsFetcher from 'modules/results-fetcher';
import { LoadingAnimation } from '../loading-animation/loading-animation';

const defaultParams = {
  calendarTeaserElem: null,
  calendarElem: null,
  eventChannelElem: null,
  dataEndpoint: null,
  resultsCache: null,
  isSpectrum: false,
};

class CalendarLoader {
  constructor(inParams) {
    const params = { ...defaultParams, ...inParams };

    this.mCalendarTeaserElem = params.calendarTeaserElem;
    this.mCalendarElem = params.calendarElem;
    this.mEventChannelElem = params.eventChannelElem;
    this.mDataEndpoint = params.dataEndpoint;
    this.mResultsCache = params.resultsCache ? params.resultsCache : {};
    this.mIsSpectrum = params.isSpectrum;
    this.mResultsFetcher = null;
    this.mLoadingAnimation = null;
  }

  init = () => {
    if (!this.mCalendarElem || !this.mEventChannelElem || !this.mDataEndpoint) {
      return;
    }

    this.mResultsFetcher = new ResultsFetcher({
      onLoaded: data => {
        this.mLoadingAnimation.hide();
        this.mCalendarTeaserElem.classList.add('calendar-teaser--ready');
        this._createDateBtnListElem(data);
      },
      onError: error => console.error(error),
    });

    if (this.mDataEndpoint) {
      this.mLoadingAnimation = new LoadingAnimation(
        this.mCalendarTeaserElem,
        this.mIsSpectrum
      );

      this.mLoadingAnimation.show();
      this.mResultsFetcher.fetch(this.mDataEndpoint);
    }
  };

  _createDateBtnListElem = listDataArr => {
    const dragscrollElem = $('.dragscroll', this.mCalendarElem);
    const listElem = document.createElement('ul');
    listElem.setAttribute('aria-live', 'polite');
    listElem.setAttribute('tabindex', '0');

    listDataArr.forEach(data => {
      const date = dateAndTime.parse(data.date, 'YYYY-MM-DD');
      const day = dateAndTime.format(date, 'DD');
      const month = dateAndTime.format(date, 'MMM').slice(0, 3).toUpperCase();

      // update the cache
      if (data.indicesHtml) {
        this.mResultsCache[data.date] = data.indicesHtml;
      }

      const listItemStr = this._createListItemStr({
        url: data.endpoint || '',
        dateTime: data.date,
        day,
        month,
      });

      listElem.insertAdjacentHTML('beforeend', listItemStr);
    });

    dragscrollElem.appendChild(listElem);

    new CalendarScroller(this.mCalendarElem, this.mEventChannelElem).init();
  };

  _createListItemStr = (
    param = {
      url: '',
      dateTime: '',
      day: '',
      month: '',
    }
  ) =>
    `<li>
      <button
        class="ct-1__date"
        data-url="${param.url}">
        <time class="ct-1__date-content" datetime="${param.dateTime}" aria-hidden="true">
          <span>${param.day}</span>
          <span>${param.month}</span>
        </time>
        <span class="sr-only">Unsere Veranstaltungen am <time datetime="${param.dateTime}">${param.dateTime}</time></span>
      </button>
    </li>`;
}

export default CalendarLoader;
