/* eslint-disable import/no-anonymous-default-export */
const lang = document.documentElement.lang;
const labels = {
  prev_de: 'Zum vorherigen Bild springen',
  prev_en: 'move to previous image',
  next_de: 'Zum nächsten Bild springen',
  next_en: 'move to next image',
  close_de: 'Lightbox Schließen (Esc)',
  close_en: 'Close lightbox (Esc)',
  zoom_de: 'Bild vergrößern/verkleinern',
  zoom_en: 'Zoom image in/out',
};

export default `
<div class="pswp" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="pswp__bg"></div>
  <div class="pswp__scroll-wrap">
    <div class="pswp__container">
      <div class="pswp__item"></div>
      <div class="pswp__item"></div>
      <div class="pswp__item"></div>
    </div>
    <div class="pswp__ui pswp__ui--hidden">
      <div class="pswp__top-bar">
        <div class="pswp__counter"></div>
        <div class="pswp_imagetitle_text">
          <p id="pswp_imagetitle"></p>
        </div>
        <button class="pswp__button pswp__button--close" title="${
          labels[`close_${lang}`]
        }">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path fill="#000" d="M0 18.519L18.519 0 20 1.481 1.481 20z"/>
            <path fill="#000" d="M1.481 0L20 18.519 18.519 20 0 1.481z"/>
          </svg>
        </button>
        <button class="pswp__button pswp__button--zoom" title="${
          labels[`zoom_${lang}`]
        }">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28">
            <circle cx="10" cy="10" r="9" stroke="#000" fill="transparent" stroke-width="2"/>
            <rect width="2" height="8" x="9" y="6" fill="#000"/>
            <rect width="2" height="13.65" x="20.85" y="14.68" fill="#000" transform="rotate(-45 21.847 21.503)"/>
            <rect width="8" height="2" x="6" y="9" fill="#000"/>
          </svg>
        </button>
        <div class="pswp__preloader">
          <div class="pswp__preloader__icn">
            <div class="pswp__preloader__cut">
              <div class="pswp__preloader__donut"></div>
            </div>
          </div>
        </div>
      </div>
      <button class="pswp__button pswp__button--arrow--left" title="${
        labels[`prev_${lang}`]
      }">
        <span aria-hidden="true" class="icon-arrow-left"></span>
        <span class="sr-only">${labels[`prev_${lang}`]}</span>
      </button>
      <button class="pswp__button pswp__button--arrow--right" title="${
        labels[`next_${lang}`]
      }">
        <span aria-hidden="true" class="icon-arrow-right"></span>
        <span class="sr-only">${labels[`next_${lang}`]}</span></button>
      <div class="pswp__caption">
        <div class="pswp__caption__center container"></div>
      </div>
    </div>
  </div>
</div>
`;
