import DragScroll from './dragscroll';
import { addListener, $$, dispatchEvent } from 'utils/dom';
import AniScroll from './aniscroll';

class DragScrollSnap {
  static EventIn = {
    DISABLE_SNAP: 'dragscroll-snap.event-in.disable-snap',
    SNAP: 'dragscroll-snap.event-in.snap',
    SNAP_VERTICALLY: 'dragscroll-snap.event-in.snap-vertically',
    SNAP_HORIZONTALLY: 'dragscroll-snap.event-in.snap-horizontally',
  };

  static EventOut = {
    DRAG_END: 'dragscroll-snap.event-out.drag-end',
  };

  constructor(dragScrollElem, eventChannelElem) {
    this.mDragScrollElem = dragScrollElem;
    this.mEventChannelElem = eventChannelElem;
    // this event channel is used to communicate with the DragScroll module
    this.mInternalEventChannelElem = document.createElement('div');
  }

  init = () => {
    if (!this.mDragScrollElem || !this.mEventChannelElem) {
      return;
    }

    this.mCurrentPos = undefined;
    this.mCurrentScrollTop = undefined;
    this.mCurrentScrollLeft = undefined;

    this.mIsSnapTop = true;
    this.mIsSnapLeft = true;

    this._events();

    this.mAniScrollSpeed = 300;
    this.mAniScroll = new AniScroll({
      elem: this.mDragScrollElem,
      easing: AniScroll.Easing.EASE_IN_OUT_CUBIC,
      onAnimationEnd: () =>
        dispatchEvent(this.mEventChannelElem, DragScrollSnap.EventOut.DRAG_END),
    });

    new DragScroll(this.mDragScrollElem, this.mInternalEventChannelElem).init();

    this._update();
  };

  _events = () => {
    // handle DragScroll events
    addListener(
      this.mInternalEventChannelElem,
      DragScroll.EventOut.DRAG_END,
      this._update
    );

    // handle DragScrollSNap events
    addListener(
      this.mEventChannelElem,
      DragScrollSnap.EventIn.DISABLE_SNAP,
      () => {
        this.mIsSnapTop = false;
        this.mIsSnapLeft = false;
      }
    );
    addListener(this.mEventChannelElem, DragScrollSnap.EventIn.SNAP, () => {
      this.mIsSnapTop = true;
      this.mIsSnapLeft = true;
      this._update();
    });
    addListener(
      this.mEventChannelElem,
      DragScrollSnap.EventIn.SNAP_HORIZONTALLY,
      () => {
        this.mIsSnapTop = false;
        this.mIsSnapLeft = true;
        this._update();
      }
    );
    addListener(
      this.mEventChannelElem,
      DragScrollSnap.EventIn.SNAP_VERTICALLY,
      () => {
        this.mIsSnapTop = true;
        this.mIsSnapLeft = false;
        this._update();
      }
    );
  };

  _update = () => {
    this.mIsSnapLeft && this._updateScrollLeft();
    this.mIsSnapTop && this._updateScrollTop();

    if (this.mIsSnapLeft && !this.mIsSnapTop) {
      this.mAniScroll.scroll(this.mCurrentScrollLeft, 0, this.mAniScrollSpeed);
    } else if (!this.mIsSnapLeft && this.mIsSnapTop) {
      this.mAniScroll.scroll(0, this.mCurrentScrollTop, this.mAniScrollSpeed);
    } else if (this.mIsSnapLeft && this.mIsSnapTop) {
      this.mAniScroll.scroll(
        this.mCurrentScrollLeft,
        this.mCurrentScrollTop,
        this.mAniScrollSpeed
      );
    }
  };

  _updateScrollTop = () => {
    const scrollTop = this.mDragScrollElem.scrollTop;
    let elemTop = 0;
    let elemHeight = 0;
    let elemPos = 0;
    let index = 0;
    let doSnap = false;

    $$(':scope > ul > li', this.mDragScrollElem).forEach(elem => {
      const t = elem.offsetTop;
      const h = elem.offsetHeight;
      if (t <= scrollTop && t + h > scrollTop) {
        elemTop = t;
        elemHeight = h;
        elemPos = index;
        doSnap = true;
      }
      index++;
    });

    if (!doSnap) {
      return;
    }

    let newScrollTop = elemTop;
    if (elemTop + elemHeight / 2 < scrollTop) {
      newScrollTop += elemHeight;
    }

    this.mCurrentPos = elemPos;
    this.mCurrentScrollTop = newScrollTop;
  };

  _updateScrollLeft = () => {
    const scrollLeft = this.mDragScrollElem.scrollLeft;
    let elemLeft = 0;
    let elemWidth = 0;
    let elemPos = 0;
    let index = 0;
    let doSnap = false;

    $$(':scope > ul > li', this.mDragScrollElem).forEach(elem => {
      const t = elem.offsetLeft;
      const h = elem.offsetWidth;
      if (t <= scrollLeft && t + h > scrollLeft) {
        elemLeft = t;
        elemWidth = h;
        elemPos = index;
        doSnap = true;
      }
      index++;
    });

    if (!doSnap) {
      return;
    }

    let newScrollLeft = elemLeft;
    if (elemLeft + elemWidth / 2 < scrollLeft) {
      newScrollLeft += elemWidth;
    }

    this.mCurrentPos = elemPos;
    this.mCurrentScrollLeft = newScrollLeft;
  };
}

export default DragScrollSnap;
