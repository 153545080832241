import { $ } from 'utils/dom';
import ResultsLoader from './_results-loader';
import ReducersDrawer from './_reducers-drawer';

export class SearchFilter {
  constructor(searchFilterElem) {
    this.searchFilterElem = searchFilterElem;

    if (!this.searchFilterElem) return;

    this.drawerContainerElem = $('.sf__drawer', this.searchFilterElem);
    this.drawerBtnElem = $('.sf__reducers-caption', this.searchFilterElem);

    this.currentDeviceType = null;

    this.init();
  }

  init = () => {
    if (
      !this.searchFilterElem ||
      !this.drawerContainerElem ||
      !this.drawerBtnElem
    ) {
      return;
    }

    new ResultsLoader({
      searchFilterElem: this.searchFilterElem,
    }).init();

    new ReducersDrawer(this.drawerContainerElem, this.drawerBtnElem).init();
  };
}
