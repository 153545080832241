import AniScroll from '../../../shared/js/modules/aniscroll';
import { addListener, $ } from '../../../shared/js/utils/dom';

export class ScrollTopBtn {
  constructor(scrollTopBtnElem) {
    this.mScrollTopBtnElem = scrollTopBtnElem
      ? $('.stb-1__btn', scrollTopBtnElem)
      : null;

    this.mBtnIsVisible = false;

    this._init();
  }

  _init = () => {
    if (!this.mScrollTopBtnElem) return;

    this.mAniScroll = new AniScroll({
      easing: AniScroll.Easing.EASE_IN_OUT_CUBIC,
    });

    this._events();

    this._onScroll();
  };

  _events = () => {
    addListener(this.mScrollTopBtnElem, 'click', () =>
      this.mAniScroll.scroll(0, 0, 1000)
    );
    // cancel the scrolling animation for these events
    addListener(window, 'mousedown touchstart wheel', this.mAniScroll.cancel);
    addListener(window, 'scroll', this._onScroll);
  };

  _onScroll = () => {
    const triggerDistance = window.innerHeight - window.pageYOffset;
    if (triggerDistance <= 0 && !this.mBtnIsVisible) {
      this.mScrollTopBtnElem.classList.add('stb-1__btn--show');
      this.mBtnIsVisible = true;
    } else if (triggerDistance > 0 && this.mBtnIsVisible) {
      this.mScrollTopBtnElem.classList.remove('stb-1__btn--show');
      this.mBtnIsVisible = false;
    }
  };
}
