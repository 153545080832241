import { $ } from 'utils/dom';
import { getScrollTop, setScrollTop } from 'utils/scroll';
import { MobileMenu } from './mobile-menu';

export class MobileNav {
  constructor(el) {
    this.el = el;
    this.toggle = $('.nav-toggle');
    this.header = $('.header');
    this.navbar = $('.navbar', this.header);
    this.nav = $('.nav', this.nav);

    this.isToggled = false;
    this.isAnimating = false;
    this.menuIsOpen = false;

    this.toggle.addEventListener('click', this.onToggle.bind(this));
  }

  onToggle() {
    if (this.isAnimating) return;

    if (this.mobileMenuInitialized === undefined) {
      this.mobileMenu = new MobileMenu($('.menu'));
      this.mobileMenuInitialized = true;
    }

    this.isToggled = !this.isToggled;

    // save and restore scroll position
    if (this.isToggled) {
      this.lastScrollTop = getScrollTop();
    } else {
      setTimeout(() => {
        setScrollTop(this.lastScrollTop);
      }, 10);
    }

    this.el.classList.toggle('is-toggled');
    this.header.classList.toggle('is-nav-toggled');
    document.documentElement.classList.toggle('is-mobile-nav-open');
    document.body.classList.toggle('is-mobile-nav-open');
    this.isAnimating = true;
    this.menuIsOpen = true;
    this.el.slideToggle(500).then(() => {
      this.isAnimating = false;
      if (this.isToggled) {
        // is open
        this.menuIsOpen = true;
        const height = window.innerHeight - this.navbar.offsetHeight; // fix wrong vh calculation in some mobile browsers
        this.nav.style.height = `${height}px`;
      } else {
        this.nav.style.height = '';
        this.menuIsOpen = false;
      }
    });
  }

  closeByEsc() {
    document.addEventListener('keydown', e => {
      if (e.key === 'Escape') {
        this.nav.style.height = '';
        this.nav.classList.remove('is-toggeled');
      }
    });
  }
}
