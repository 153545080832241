import {
  $,
  $$,
  getElementIndex,
  isImageLoaded,
  insertHTML,
} from '../../../shared/js/utils/dom';
import Slider from 'modules/Slider/Slider';
import { loadingAnimationTemplate } from '../loading-animation/loading-animation';

export class Highlights {
  constructor(el) {
    this.el = el;
    this.itemsContainer = $('.highlight-list', this.el);
    this.items = $$('.highlight', this.el);
    this.navItems = $$('.highlights-nav-item', this.el);

    const randomIndex = Math.floor(Math.random() * this.items.length);
    this.select(randomIndex);
    this.initSlider(randomIndex);

    this.navItems.forEach(navItem => {
      navItem.addEventListener('click', this.onNavItemClick.bind(this));
    });
  }

  onNavItemClick(event) {
    event.preventDefault();
    const index = getElementIndex(event.currentTarget);
    this.select(index);
  }

  async select(index) {
    const item = this.items[index];
    const navItem = this.navItems[index];
    const selectedItem = this.items[this.selectedIndex];
    const selectedNavItem = this.navItems[this.selectedIndex];

    if (selectedItem) {
      selectedItem.classList.remove('is-selected');
      selectedNavItem.classList.remove('is-selected');
    }

    this.lazyLoad(item);
    item.classList.add('is-selected');
    navItem.classList.add('is-selected');
    this.selectedIndex = index;
  }

  async lazyLoad(item) {
    if (item.classList.contains('is-loaded')) return;

    const highlightImage = $('.highlight-image', item);
    // add loading animation
    insertHTML(highlightImage, 'beforeend', loadingAnimationTemplate);
    // wait for image to load
    const img = $('img', highlightImage);
    const isResponsiveImage = img.parentNode.tagName === 'PICTURE';
    if (isResponsiveImage) {
      const sources = $$('source', img.parentNode);
      sources.forEach(source => {
        source.srcset = source.dataset.srcset;
      });
    }

    img.src = img.dataset.src;
    await isImageLoaded(img);

    // mark item as loaded and remove loading animation
    item.classList.add('is-loaded');
    const loadingAnimation = $(':scope > .loading-animation', highlightImage);
    loadingAnimation.parentNode.removeChild(loadingAnimation);
  }

  initSlider() {
    this.slider = new Slider(this.itemsContainer, {
      config: {
        prevNextButtons: false,
        initialIndex: this.selectedIndex,
        adaptiveHeight: true,
      },
      conditions: [
        {
          breakpoint: 'less-equal 1024',
          isSlider: true,
        },
        {
          breakpoint: 'more 1024',
          isSlider: false,
        },
      ],
      events: {
        // select item when changing slide
        onChange: slider => {
          this.select(slider.selectedIndex);
        },
        // re-trigger item selection as flickity removes item classes
        onDestroy: () => {
          this.select(this.selectedIndex);
        },
        // slide to currently selected item when re-initializing
        onUpdateConfig: () => {
          return {
            initialIndex: this.selectedIndex,
          };
        },
      },
    });
    this.slider.init();
  }
}
