import { $ } from 'utils/dom';
import FiltersChangeNotifier from '../calendar-filter/_filters-change-notifier';
import { strToDate, DateToFormatedStr } from '../calendar-filter/_helper';

class ResultsFetcher {
  static EventOut = {
    LOADING: 'ResultsLoader.EventOut.LOADING',
    LOADED: 'ResultsLoader.EventOut.LOADED',
    CANCELED: 'ResultsLoader.EventOut.CANCELED',
  };

  static EventIn = {
    GET: 'ResultsLoader.EventIn.GET',
  };

  constructor(calendarElem) {
    this.mCalendarElem = calendarElem;
    this.mCancelFunc = null;
    this.mResultsElem = null;
    this.mCalendarFilterElem = null;

    this.mUri = null;

    // timeout in milliseconds
    this.mFiltersChangedTimeout = 700;
    this.mFiltersChangedTimeoutId = null;

    this.mIsReady = true;
  }

  init = () => {
    if (!this.mCalendarElem) return;

    this.mResultsElem = $(
      ':scope > section.calendar__results',
      this.mCalendarElem
    );

    // get the uri
    this.mCalendarFilterElem = $('.calendar-filter', this.mCalendarElem);
    this.mCalendarFilterElem &&
      (this.mUri = this.mCalendarFilterElem.dataset.uri);

    if (!this.mResultsElem || !this.mUri) return;

    this._events();
  };

  _events = () => {
    const calendarFilterElem = $('.calendar-filter', this.mCalendarElem);

    // listen to filters events
    calendarFilterElem &&
      calendarFilterElem.addEventListener(
        FiltersChangeNotifier.Event.FILTERS_CHANGED,
        e => {
          this.mFiltersChangedTimeoutId &&
            clearTimeout(this.mFiltersChangedTimeoutId);

          this.mFiltersChangedTimeoutId = setTimeout(() => {
            this.mFiltersChangedTimeoutId = null;

            this._onFilterChanged(e.detail);
          }, this.mFiltersChangedTimeout);
        }
      );

    // listen to internal incoming events
    this.mCalendarElem.addEventListener(ResultsFetcher.EventIn.GET, e => {
      const url = e.detail;
      this._loadFromServer(url, null);
    });
  };

  _onFilterChanged = filters => {
    // get results without filtering
    if (!filters.length) {
      this._loadFromServer(this.mUri, filters);
    }
    // filter results
    else {
      const queryStr = this._filtersToQueryStr(filters);
      this._loadFromServer(`${this.mUri}&${queryStr}`, filters);
    }
  };

  _filtersToQueryStr = filters => {
    if (!filters.length) return '';

    const encodedSolrPrefix = encodeURIComponent('tx_solr[filter][]');
    const checkboxesQueryArr = this._checkboxFiltersToStrArr(
      filters,
      encodedSolrPrefix
    ).filter(Boolean);
    const dateRangeQueryStr = this._dateRangeFilterToStrArr(
      filters,
      encodedSolrPrefix
    );

    const queryStr = [...checkboxesQueryArr, dateRangeQueryStr]
      .filter(Boolean)
      .join('&');

    return queryStr;
  };

  _dateRangeFilterToStrArr = (filters, encodedSolrPrefix) => {
    let dateFrom = null;
    let dateTo = null;

    filters.forEach(f => {
      f.dateFrom && (dateFrom = new Date(strToDate(f.dateFrom)));
      f.dateTo && (dateTo = new Date(strToDate(f.dateTo)));
    });

    /* eslint-disable no-unused-expressions */
    dateFrom?.setHours(0, 0);
    const dateFromStr = DateToFormatedStr(dateFrom);

    dateTo?.setHours(23, 59);
    const dateToStr = DateToFormatedStr(dateTo);
    /* eslint-enable */

    const encodedDateRange = encodeURIComponent(
      `dateRange:${dateFromStr ?? ''}-${dateToStr ?? ''}`
    );

    return dateFrom || dateTo
      ? `${encodedSolrPrefix}=${encodedDateRange}`
      : null;
  };

  _checkboxFiltersToStrArr = (filters, encodedSolrPrefix) => {
    const mappedFilters = filters.map(filter => {
      // checkbox values
      if (filter.name) {
        const value = filter.value;
        const encodedSolrValueStr = encodeURIComponent(`${value}`);
        return `${encodedSolrPrefix}=${encodedSolrValueStr}`;
      } else {
        return null;
      }
    });

    return mappedFilters;
  };

  _loadFromServer = (url, filters) => {
    const that = this;

    this._dispatchEvent(ResultsFetcher.EventOut.LOADING, {
      filters,
      url,
    });

    import(/* webpackChunkName: 'axios' */ 'axios').then(_ => {
      const axios = _.default;

      // cancel the running request
      that.mCancelFunc && that.mCancelFunc();

      axios
        .get(url, {
          cancelToken: new axios.CancelToken(function executor(c) {
            that.mCancelFunc = c;
          }),
        })
        .then(({ data }) => {
          that._dispatchEvent(ResultsFetcher.EventOut.LOADED, data);
        })
        .catch(function (thrown) {
          that._dispatchEvent(ResultsFetcher.EventOut.CANCELED);
        });
    });
  };

  _dispatchEvent = (eventName, data) => {
    const event = new CustomEvent(eventName, { detail: data });
    this.mCalendarElem.dispatchEvent(event);
  };
}

export default ResultsFetcher;
