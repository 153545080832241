import { $, $$ } from 'utils/dom';
import { Cookiebanner, cookiebannerTemplateTablist } from '@3pc/cookiebanner';
import {
  hideCookiebanner,
  imprintLink,
  privacyPolicyLink,
  cookiebannerLabels,
  consentTypes,
} from './cookiebanner-variables';
import { ConsentOverlay, handleEmbed } from '@3pc/consent-overlay';
import { observe } from '../media/player/player';
const consentOverlays = [];

// eslint-disable-next-line no-new
new Cookiebanner({
  nameSpace: 'technikmuseum',
  consentButtonTypes: 'required-all',
  cookiebannerTemplate: cookiebannerTemplateTablist,
  labels: cookiebannerLabels,
  hideCookiebanner,
  imprintLink,
  privacyPolicyLink,
  showButtonsAboveTablist: true,
  consentTypes,
  onInit: () => {
    const overlays = $$('[data-consent-type]');
    overlays.length &&
      overlays.forEach(overlay => {
        const consentOverlay = new ConsentOverlay({
          container: overlay,
          nameSpace: 'technikmuseum',
          onActivation: async (container, consentType) => {
            if (consentType === 'embed') {
              const subtype = overlay.dataset.consentSubtype;
              if (subtype === 'podigee') {
                // handlePodcasts(container, true);
                await handleEmbed(container, true);
                setTimeout(() => {
                  const iframe = $('iframe', container);
                  iframe.dataset.src = iframe.src;
                }, 5000);
              } else {
                handleEmbed(container, true);
              }
            }
            if (consentType === 'video') {
              const plyrEmbeds = [
                ...$$('.player-wrapper--youtube', container),
                ...$$('.player-wrapper--vimeo', container),
              ];
              console.log(plyrEmbeds);
              plyrEmbeds.length && observe(plyrEmbeds);
            }
          },
          onDeactivation: (container, consentType) => {
            if (consentType === 'embed') {
              const subtype = overlay.dataset.consentSubtype;
              handleEmbed(container, false);
              if (subtype === 'podigee') window.location.reload();
            }
            if (consentType === 'video') {
              window.location.reload();
            }
          },
        });
        consentOverlay.init();
        consentOverlays.push(consentOverlay);
      });
  },
  onOptIn: consentType => {
    consentOverlays.forEach(overlay => {
      if (overlay.consentType === consentType.type) {
        overlay.toggleAll();
      }
    });
  },
  onOptOut: consentType => {
    consentOverlays.forEach(overlay => {
      if (overlay.consentType === consentType.type) {
        overlay.toggleAll();
      }
    });
  },
}).init();
