import { addListener, $ } from 'utils/dom';
import UsedFilterCheckbox from '../_used-filters/used-filter-checkbox';

const defaultOpt = {
  checkboxGroupElem: null,
  eventScopeElem: document.documentElement,
};

class CheckboxGroup {
  static Event = {
    IS_CHANGED: 'cf-1.checkbox.is-changed',
    UPDATE_STATE: 'cf-1.checkbox.update-state',
  };

  constructor(options) {
    const opt = Object.assign(defaultOpt, options);

    this.mEventScopeElem = opt.eventScopeElem;
    this.mCheckboxGroupElem = opt.checkboxGroupElem;
    this.mCheckboxElem = $('input[type="checkbox"]', this.mCheckboxGroupElem);

    this.mName = this.mCheckboxElem.getAttribute('name');
    this.mValue = this.mCheckboxElem.getAttribute('value');
    this.mCaption = $('label', this.mCheckboxGroupElem).textContent.trim();

    this.mCheckboxElem && this._setCheckboxValueFromUri();
    this.mEventScopeElem && this._events();
  }

  _setCheckboxValueFromUri = () => {
    const activeFilters = window.location.search.split(
      /[&?]tx_solr%5Bfilter%5D%5B%5D=/gi
    );

    this.mCheckboxElem.checked = this._isCheckboxInUrl(activeFilters);
  };

  // Given the url "?location:spectrum" which is technically allowed
  // but encoded to "?location%3Aspectrum" by the encodeURIComponent function,
  // we check for both encoded and raw colons to be a little safer
  _isCheckboxInUrl = url =>
    url.includes(`${this.mValue}`) ||
    url.includes(encodeURIComponent(`${this.mValue}`));

  _events = () => {
    // add highlighting
    addListener(this.mCheckboxElem, 'focusin', () =>
      this.mCheckboxGroupElem.classList.add('cf-1__checkbox-group--highlight')
    );

    // remove highlighting
    addListener(this.mCheckboxElem, 'focusout', () =>
      this.mCheckboxGroupElem.classList.remove(
        'cf-1__checkbox-group--highlight'
      )
    );

    // announce state change
    addListener(this.mCheckboxElem, 'change', this._sendIsChangedEvent);

    // inform about the current state
    addListener(
      this.mEventScopeElem,
      CheckboxGroup.Event.UPDATE_STATE,
      this._sendIsChangedEvent
    );

    // uncheck the checkbox if the filter with same parameters is removed from the used filter list
    addListener(
      this.mEventScopeElem,
      UsedFilterCheckbox.Event.IS_REMOVED,
      e => {
        const remove =
          e.detail.name === this.mName && e.detail.value === this.mValue;
        remove && (this.mCheckboxElem.checked = false);
      }
    );
  };

  _sendIsChangedEvent = () => {
    const event = new CustomEvent(CheckboxGroup.Event.IS_CHANGED, {
      detail: {
        name: this.mName,
        value: this.mValue,
        caption: this.mCaption,
        checked: this.mCheckboxElem.checked,
      },
    });
    this.mEventScopeElem.dispatchEvent(event);
  };
}

export default CheckboxGroup;
