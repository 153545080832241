export const chunk = (arr, n) => {
  if (!arr.length) return [];
  return [arr.slice(0, n)].concat(chunk(arr.slice(n), n));
};

export const urlify = (str, url) =>
  str.replace(/#(\S*)/g, `<a href="${url}$1">#$1</a>`);

export const isImageLoaded = image => {
  if (!image) {
    return Promise.reject(new Error('No image detected'));
  } else if (typeof image === 'string') {
    // create an <img> from a string, treat it like a src
    const src = image;
    image = new Image();
    image.src = src;
  } else if (image.tagName !== 'IMG') {
    return Promise.reject(new Error('No image detected'));
  }
  return new Promise(resolve => {
    const { src } = image;
    if (image.naturalWidth) {
      // If the browser can determine the naturalWidth the
      // image is already loaded successfully
      resolve({ src, status: 'ok' });
    } else if (image.complete) {
      // If the image is complete but the naturalWidth is 0
      // it is probably broken
      resolve({ src, status: 'error' });
    } else {
      image.onload = () => resolve({ src, status: 'ok' });
      image.onerror = () => resolve({ src, status: 'error' });
    }
  });
};

export const merge = (...objects) => Object.assign({}, ...objects);

export const flatEqual = (obj1, obj2) =>
  JSON.stringify(obj1) === JSON.stringify(obj2);

export const toJSON = str => {
  try {
    const o = JSON.parse(str);
    if (o && typeof o === 'object') {
      return o;
    }
  } catch (error) {
    // do nothing
  }
  return false;
};

export const encodeData = obj =>
  Object.keys(obj)
    .map(key => [key, obj[key]].map(encodeURIComponent).join('='))
    .join('&');

export const splitByCondition = (fn, arr) => {
  return arr.reduce(
    ([Truthy, Falsy], x, i, arr) => {
      if (fn(x, i, arr) === false) return [Truthy, [...Falsy, x]];
      else return [[...Truthy, x], Falsy];
    },
    [[], []]
  );
};

export const uniqueId = () => Math.floor(new Date().valueOf() * Math.random());

export const secToHHMMSS = seconds =>
  [
    parseInt(seconds / 60 / 60),
    parseInt((seconds / 60) % 60),
    parseInt(seconds % 60),
  ]
    .join(':')
    .replace(/\b(\d)\b/g, '0$1');

export const getNestedObject = (inObj, inPathArr) => {
  return inPathArr.reduce(
    (obj, key) => (obj && obj[key] ? obj[key] : null),
    inObj
  );
};

export const clamp = (num, min, max) =>
  num <= min ? min : num >= max ? max : num;
