import { addListener, removeListener, addStyles } from 'utils/dom';

/*
  Consider to hide the drawer with "display: none"
  to prevent it from showing up before the initialization.
 */

const defaultOptions = {
  containerElem: undefined,
  transitionSpeed: '300ms',
};

class Drawer {
  constructor(options) {
    const opt = Object.assign(defaultOptions, options);

    this.mContainerElem = opt.containerElem;

    if (this.mContainerElem) {
      this._onInitCompleteListener();

      this.mContainerElem.classList.add('drawer');
      this.mContentElem = this.mContainerElem.firstElementChild;
      this.mContentElem && this.mContentElem.classList.add('drawer__content');

      if (opt.transitionSpeed) {
        this.mContainerElem.style.transition = `height ${opt.transitionSpeed}, visibility ${opt.transitionSpeed}`;
      }

      this.mContainerElem.style.display = 'block';
    }

    this.mIsOpened = false;
    this.mIsDestroyed = false;
    this.mIsInitComplete = false;
  }

  isOpen = () => {
    return this.mIsOpened;
  };

  open = () => {
    if (!this.mIsDestroyed && !this.mIsOpened) {
      this._open();
      return true;
    }

    return false;
  };

  close = () => {
    if (!this.mIsDestroyed && this.mIsOpened) {
      this._close();
      return true;
    }

    return false;
  };

  toggle = () => {
    return this.isOpen() ? this.close() : this.open();
  };

  destroy = () => {
    this.mIsDestroyed = true;

    // close drawer if it is opened
    this.mIsOpened && this._close();

    if (this.mContainerElem) {
      // reset elements
      addStyles(this.mContainerElem, {
        overflow: '',
        height: '',
        transition: '',
      });
      // removed classes
      this.mContainerElem.classList.remove('drawer');
    }

    if (this.mContentElem) {
      // reset elements
      this.mContentElem.style.position = '';
      // removed classes
      this.mContentElem.classList.remove('drawer__content');
    }
  };

  _onInitCompleteListener = () => {
    if (this.mIsInitComplete) {
      return;
    }

    const drawerCreatedObserver = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        // if 'transition' is set then
        // drawer initialization is complete
        if (mutation.target.style.transition) {
          this.mIsInitComplete = true;
          drawerCreatedObserver.disconnect();
        }
      });
    });

    drawerCreatedObserver.observe(this.mContainerElem, {
      attributes: true,
    });
  };

  _addOnOpenTransitionEndListener = () => {
    addListener(
      this.mContainerElem,
      'webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend',
      this._onOpenTransitionEnd
    );
  };

  _removeOnOpenTransitionEndListener = () => {
    removeListener(
      this.mContainerElem,
      'webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend',
      this._onOpenTransitionEnd
    );
  };

  _open = () => {
    this._removeOnOpenTransitionEndListener();
    this._addOnOpenTransitionEndListener();

    this.mContainerElem.style.height = `${this.mContentElem.offsetHeight}px`;
    this.mContainerElem.style.visibility = 'visible';
    this.mIsOpened = true;

    // styles initialization is not ready yet,
    // so jump to the 'transition end' state immediately
    !this.mIsInitComplete && this._onOpenTransitionEnd();
  };

  _close = () => {
    this._removeOnOpenTransitionEndListener();

    this.mContentElem.style.position = '';

    this.mContainerElem.style.height = `${this.mContentElem.offsetHeight}px`;
    this.mContainerElem.style.overflow = '';

    // eslint-disable-next-line no-unused-expressions
    this.mContainerElem.offsetHeight; // forces repaint
    this.mContainerElem.style.height = '';
    this.mContainerElem.style.visibility = '';

    this.mIsOpened = false;
  };

  _onOpenTransitionEnd = () => {
    // should be triggered once
    this._removeOnOpenTransitionEndListener();

    this.mContainerElem.style.height = 'auto';
    this.mContainerElem.style.overflow = 'visible';

    this.mContentElem.style.position = 'relative';
  };
}

export default Drawer;
