import Bus from 'modules/event-bus';
import { merge, flatEqual, uniqueId } from 'utils/helper';
import { insertNodeAfter } from 'utils/dom';
import FLICKITY_DEFAULTS from './flickity-defaults';

// TODO: error handling
let Flickity;

export default class Slider {
  CONDITION_DEFAULTS = {
    breakpoint: '*',
    isSlider: true,
  };

  DISABLED_FLICKITY_CONFIGS = {
    adaptiveHeight: false,
    setGallerySize: false,
    resize: false,
    // autoPlay: false,
    accessibility: false,
  };

  FAKE_SLIDER = {
    pageDots: false,
    prevNextButtons: false,
    draggable: false,
  };

  LOADED_CLASS = 'is-initialized';

  constructor(target, options = {}, lightbox) {
    this.target = target;
    this.uniqueId = uniqueId();
    this.options = options;
    this.lightbox = lightbox;
    this.items = this.target.children;
    this.pageNumberElement = null;
    this.baseGap = this.options.gap || '0px';
    this.baseConfig = merge(FLICKITY_DEFAULTS, this.options.config);
    this.conditions = this.sanitizeConditions(this.options.conditions);
    this.events = options.events || {};
    this.state = this.getState();
    this.slider = null;
    this.slides = [];
    this.selectedMaxHeight = 0;
    this.forceUpdate = false;
    this.syncSlider = options.syncWithSlider || null;
  }

  getState = () => {
    // Returns first matching condition or `undefined`
    // eslint-disable-next-line array-callback-return
    return this.conditions.find(state => {
      const { breakpoint } = state;
      const [operator, width] = breakpoint;

      if (operator === '*') return true;
      if (operator === 'less') {
        return window.innerWidth < width;
      }
      if (operator === 'less-equal') {
        return window.innerWidth <= width;
      }
      if (operator === 'more') {
        return window.innerWidth > width;
      }
      if (operator === 'more-equal') {
        return window.innerWidth >= width;
      }
    });
  };

  mergeConfigs = condition => {
    // init slider but remove slider behaviour if equal or less items
    // than slides per page
    const slidesPerPage =
      (condition.config && condition.config.groupCells) ||
      this.baseConfig.groupCells ||
      1;

    const isFakeSlider = this.items.length <= slidesPerPage;
    const fakeConfig = isFakeSlider ? this.FAKE_SLIDER : {};

    return merge(this.baseConfig, condition.config, fakeConfig);
  };

  sanitizeConditions = (conditions = [this.CONDITION_DEFAULTS]) => {
    return conditions
      .filter(condition => condition.breakpoint)
      .map(condition => ({
        ...merge(
          this.CONDITION_DEFAULTS,
          {
            gap: this.baseGap,
          },
          condition,
          {
            config: this.mergeConfigs(condition),
          }
        ),
        breakpoint: condition.breakpoint.split(' '),
      }));
  };

  syncListener = () => {
    const index = this.slider.selectedIndex;
    const syncSlider = this.syncSlider.slider;

    // let the syncSlider slides with the main slider
    syncSlider.select(index);
  };

  syncSliderEvents = () => {
    const syncSlider = this.syncSlider.slider;

    // if there is a syncSlider, listen on his events too
    syncSlider.on('change', () =>
      this.slider.select(this.syncSlider.slider.selectedIndex)
    );
  };

  bindEvents = () => {
    // Slider Events also used by the Slider Class
    this.slider.on('change', () => {
      // Update height after current slide has change
      this.setSliderHeight();
      this.events.onChange && this.events.onChange(this.slider);

      this.state.config.pageNumbers && this.updatePageNumbers();

      this.syncSlider && this.syncListener();
    });
    this.slider.on('staticClick', (event, pointer, cellElement, cellIndex) => {
      if (this.lightbox) {
        this.lightbox.open(event).then(() => {
          // Check if we can a proper index
          if (!this.lightbox.pswp) return;
          // Synchronize Slider and Lightbox
          this.lightbox.pswp.listen('afterChange', () => {
            const index = this.lightbox.pswp.getCurrentIndex();
            this.slider.select(index, false, true);
          });
        });
      }
      this.events.onStaticClick &&
        this.events.onStaticClick(
          event,
          pointer,
          cellElement,
          cellIndex,
          this.slider
        );
    });

    this.slides.forEach(slide => {
      slide.addEventListener('keyup', event => {
        if (event.keyCode === 13 || event.keyCode === 32) {
          if (this.lightbox) {
            this.lightbox.open(event).then(() => {
              // Check if we can a proper index
              if (!this.lightbox.pswp) return;
              // Synchronize Slider and Lightbox
              this.lightbox.pswp.listen('afterChange', () => {
                const index = this.lightbox.pswp.getCurrentIndex();
                this.slider.select(index, false, true);
              });
            });
          }
        }
      });
    });
    // Slider Events only used by Callbacks
    // Just passing through arguments
    // Check the docs for more info on passed values
    this.events.onScroll &&
      this.slider.on('scroll', (...args) =>
        this.events.onScroll(...args, this.slider)
      );
    this.events.onDragStart &&
      this.slider.on('dragStart', (...args) =>
        this.events.onDragStart(...args, this.slider)
      );
    this.events.onDragMove &&
      this.slider.on('dragMove', (...args) =>
        this.events.onDragMove(...args, this.slider)
      );
    this.events.onDragEnd &&
      this.slider.on('dragEnd', (...args) =>
        this.events.onDragEnd(...args, this.slider)
      );
  };

  initPagenumbers = () => {
    this.pageNumberElement = new DOMParser().parseFromString(
      `<div class="flickity-page-numbers"></div>`,
      'text/html'
    ).body.firstChild;
    insertNodeAfter(this.pageNumberElement, this.target);
    this.updatePageNumbers();
  };

  handleSlideFocus = event => {
    const index = Math.floor(
      this.slides.indexOf(event.target) / (this.state.config.groupCells || 1)
    );
    this.slider.select(index, false, false);
    // Prevents browser auto-scrolling
    this.slider.viewport.scrollLeft = 0;
  };

  makeA11y = () => {
    this.slides.forEach(slide => {
      slide.setAttribute('tabindex', 0);
      slide.addEventListener('focus', this.handleSlideFocus);
    });
  };

  resetA11y = () => {
    this.slides.forEach(slide => {
      slide.removeAttribute('tabindex');
      slide.removeEventListener('focus', this.handleSlideFocus);
    });
  };

  destroySlider = slider => {
    slider.viewport.style.height = '';
    this.pageNumberElement && this.pageNumberElement.remove();
    this.pageNumberElement = null;
    this.slides.forEach(slide => slide.removeAttribute('style'));
    this.resetA11y();
    this.slider && this.slider.destroy();
    this.events.onDestroy && this.events.onDestroy();
  };

  createSlider = (initProps = {}) => {
    this.slider && this.destroySlider(this.slider);

    if (!this.state || !this.state.isSlider) return;

    // Makes sure to disable some unwanted configs without loosing their value
    // in the state
    let config = merge(
      this.state.config,
      this.DISABLED_FLICKITY_CONFIGS,
      initProps
    );

    // Makes dynamic update for config possible
    if (this.events.onUpdateConfig) {
      const update = this.events.onUpdateConfig(config);
      config = { ...config, ...update };
    }

    this.slider = new Flickity(this.target, config);

    this.slides = this.slider.getCellElements();
    this.setSlidesWidth(this.slides);
    this.setSliderHeight();
    this.slider.reloadCells();
    this.slider.reposition();

    this.state.config.pageNumbers && this.initPagenumbers();
    this.state.config.pageDots
      ? this.target.classList.add('has-dots')
      : this.target.classList.remove('has-dots');

    this.syncSlider && this.syncSliderEvents();

    this.bindEvents();
    this.makeA11y();
  };

  getMaxHeight = nodes => {
    return nodes.reduce(
      (max, node) => (node.offsetHeight > max ? node.offsetHeight : max),
      0
    );
  };

  setSliderHeight = () => {
    // Much faster than flickity option `resize`
    const prevHeight = this.selectedMaxHeight;

    this.selectedMaxHeight = this.state.config.adaptiveHeight
      ? this.getMaxHeight(this.slider.selectedElements)
      : this.getMaxHeight(this.slides);

    if (prevHeight === this.selectedMaxHeight && !this.forceUpdate) return;

    this.slider.viewport.style.height = `${this.selectedMaxHeight}px`;
    this.forceUpdate = false;
  };

  getCSSWidth = state => {
    const { groupCells } = this.state.config;
    return groupCells
      ? `calc((100% - (${state.config.groupCells - 1} * (${state.gap}))) / ${
          state.config.groupCells
        })`
      : '';
  };

  updatePageNumbers = () => {
    const selected = this.slider.selectedIndex;
    const cellNumber = selected + 1;

    this.pageNumberElement.innerHTML = `${cellNumber} / ${this.slider.slides.length}`;
  };

  setSlidesWidth = slides => {
    slides.forEach((slide, i) => {
      const width = this.getCSSWidth(this.state);
      slide.style.width = width;
      slide.style.marginRight = this.state.gap;
    });
  };

  handleResize = () => {
    this.state && this.state.isSlider && this.setSliderHeight();

    const prevState = this.state;
    this.state = this.getState();

    if (!flatEqual(prevState, this.state)) {
      // State changed
      this.forceUpdate = true;
      this.state ? this.createSlider() : this.destroySlider(this.slider);
    }
  };

  init = initProps => {
    import(/* webpackChunkName: 'flickity' */ 'flickity').then(_ => {
      Flickity = _.default;
      this.state && this.createSlider(initProps);
      // Prevents non-styled layout before js is loaded
      // Make sure to hide slider without LOADED_CLASS via CSS
      this.target.classList.add(this.LOADED_CLASS);
      Bus.subscribe('resize', `Slider-${this.uniqueId}`, this.handleResize);
    });
  };
}
