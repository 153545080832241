import { $, addListener } from 'utils/dom';
import Drawer from 'modules/Drawer/Drawer';

const defaultOpt = {
  elem: null,
  enableOneActiveMenuOnly: true,
  eventScopeElem: document.documentElement,
};

class DropdownMenu {
  static Event = {
    IS_OPENED: 'cf-1.dropdown-menu.is-opened',
    IS_CLOSED: 'cf-1.dropdown-menu.is-closed',
    CLOSE_ALL: 'cf-1.dropdown-menu.close-all',
  };

  constructor(options) {
    const opt = Object.assign(defaultOpt, options);

    this.mEventScopeElem = opt.eventScopeElem;
    this.mDropdownMenuElem = opt.elem;
    this.mEnableOneActiveMenuOnly = opt.enableOneActiveMenuOnly;

    this.mDropdownMenuBtnElem = $('.ddm-1 > button', this.mDropdownMenuElem);
    this.mDrawerElem = $('.ddm-1 > .ddm-1__drawer', this.mDropdownMenuElem);

    this.mDrawer = new Drawer({
      containerElem: this.mDrawerElem,
    });

    this.mEventScopeElem && this._events();
  }

  _events = () => {
    // toggle dropdown menu on/off
    this.mDropdownMenuBtnElem &&
      addListener(this.mDropdownMenuBtnElem, 'click', this._toggle);

    // close menu if another menu is opened
    this.mEnableOneActiveMenuOnly &&
      addListener(
        this.mEventScopeElem,
        DropdownMenu.Event.IS_OPENED,
        e =>
          e.detail.dropdownMenuElem !== this.mDropdownMenuElem && this._close()
      );

    // close menu by receiving the event CLOSE_ALL
    addListener(
      this.mEventScopeElem,
      DropdownMenu.Event.CLOSE_ALL,
      this._close
    );
  };

  _close = () => {
    if (this.mDrawer.close()) {
      this.mDropdownMenuElem.classList.remove('ddm-1--opened');
      this._sendEvent(DropdownMenu.Event.IS_CLOSED);
    }
  };

  _open = () => {
    if (this.mDrawer.open()) {
      this.mDropdownMenuElem.classList.add('ddm-1--opened');
      this._sendEvent(DropdownMenu.Event.IS_OPENED);
    }
  };

  _toggle = () => {
    if (this.mDrawer.isOpen()) {
      this._close();
    } else {
      this._open();
    }
  };

  _sendEvent = eventName => {
    const event = new CustomEvent(eventName, {
      detail: {
        dropdownMenuElem: this.mDropdownMenuElem,
      },
    });
    this.mEventScopeElem.dispatchEvent(event);
  };
}

export default DropdownMenu;
