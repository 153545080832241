/**
 * Get current window scroll position
 *
 * @returns {Number|number}
 */
export function getScrollTop() {
  return (
    window.pageYOffset ||
    document.documentElement.scrollTop ||
    document.body.scrollTop
  );
}

/**
 * Set window scroll position
 *
 * @param scrollTop
 */
export function setScrollTop(scrollTop) {
  document.documentElement.scrollTop = scrollTop;
  document.body.scrollTop = scrollTop;
}

// debug TODO: remove
window.getScrollTop = getScrollTop;
window.setScrollTop = setScrollTop;
