import { $, addListener } from 'utils/dom';
import CheckboxGroup from '../_checkbox-group/checkbox-group';
import UsedFilters from './used-filters';

const defaultOpt = {
  name: '',
  value: '',
  caption: '',
  eventScopeElem: document.documentElement,
};

class UsedFilterCheckbox {
  static Event = {
    IS_REMOVED: 'cf-1.used-filter-checkbox.is-removed',
  };

  constructor(options) {
    const opt = Object.assign(defaultOpt, options);

    this.mEventScopeElem = opt.eventScopeElem;
    this.mName = opt.name;
    this.mValue = opt.value;
    this.mCaption = opt.caption;

    this.mFilterElem = this._createFilter(
      this.mName,
      this.mValue,
      this.mCaption
    );
    this.mFilterBtnElem = $('button', this.mFilterElem);

    this.mEventScopeElem && this._events();
  }

  getElem = () => {
    return this.mFilterElem;
  };

  _events = () => {
    // remove used filter by clicking on it
    addListener(this.mFilterBtnElem, 'click', this._removeFilter);

    // remove used filter by receiving the event REMOVE_ALL
    addListener(
      this.mEventScopeElem,
      UsedFilters.Event.REMOVE_ALL,
      this._removeFilter
    );

    // remove used filter by receiving the event CHANGED with parameter checked === false
    addListener(this.mEventScopeElem, CheckboxGroup.Event.IS_CHANGED, e => {
      !e.detail.checked &&
        e.detail.name === this.mName &&
        e.detail.value === this.mValue &&
        this._removeFilter();
    });
  };

  _removeFilter = () => {
    if (!this.mFilterElem) return;

    // remove filter
    const listElem = this.mFilterElem.parentNode;
    listElem && listElem.removeChild(this.mFilterElem);
    this.mFilterElem = null;

    // send custom event to uncheck the checkbox
    const event = new CustomEvent(UsedFilterCheckbox.Event.IS_REMOVED, {
      detail: {
        name: this.mName,
        value: this.mValue,
      },
    });

    this.mEventScopeElem.dispatchEvent(event);
  };

  _createFilter = (name, value, caption) => {
    const newFilter = document.createElement('li');
    newFilter.innerHTML = `<button type="button" data-name="${name}" data-value="${value}">${caption} <span class="icon-x"></button>`;

    return newFilter;
  };
}

export default UsedFilterCheckbox;
