/* eslint-disable import/no-anonymous-default-export */
// NOTE: learn more about flickity options https://flickity.metafizzy.co/options.html

export default {
  // enable keyboard navigation, pressing left & right keys
  accessibility: true,
  // set carousel height to the selected slide
  // is globally disabled!
  adaptiveHeight: false,
  // advances to the next cell
  // if true, default is 3 seconds
  // or set time between advances in milliseconds
  // i.e. `autoPlay: 1000` will advance every 1 second
  autoPlay: false,
  // alignment of cells, 'center', 'left', or 'right'
  // or a decimal 0-1, 0 is beginning (left) of container, 1 is end (right)
  cellAlign: 'left',
  // specify selector for cell elements
  cellSelector: undefined,
  // will contain cells to container
  // so no excess scroll at beginning or end
  // has no effect if wrapAround is enabled
  // you might want to disable it when cellAlign is centered
  contain: true,
  // enables dragging & flicking
  draggable: true,
  // number of pixels a user must scroll horizontally to start dragging
  // increase to allow more room for vertical scroll for touch devices
  dragThreshold: 20,
  // enables content to be freely scrolled and flicked
  // without aligning cells
  freeScroll: false,
  // smaller number = easier to flick farther
  friction: 0.3,
  // group cells together in slides
  groupCells: false,
  // zero-based index of the initial selected cell
  initialIndex: 0,
  // enable lazy-loading images
  // set img data-flickity-lazyload="src.jpg"
  // set to number to load images adjacent cells
  lazyLoad: false,
  // sets positioning in percent values, rather than pixels
  // Enable if items have percent widths
  // Disable if items have pixel widths, like images
  percentPosition: true,
  // creates and enables buttons to click to previous & next cells
  prevNextButtons: true,
  // create and enable page dots
  pageDots: true,
  // create and enable page numbers
  // NOTE: custom, non-standard flicktiy option
  pageNumbers: false,
  // listens to window resize events to adjust size & positions
  resize: false,
  // enables right-to-left layout
  rightToLeft: false,
  // sets the height of gallery
  // disable if gallery already has height set with CSS
  // is globally disabled!
  setGallerySize: false,
  // watches the content of :after of the element
  // activates if #element:after { content: 'flickity' }
  watchCSS: false,
  // at end of cells, wraps-around to first for infinite scrolling
  wrapAround: false,
};
