import { getNestedObject } from 'utils/helper';
import dictionary from '../translation.json';

let lang = document.documentElement.lang;
lang = lang && lang.toLowerCase();
lang = lang && ['en', 'de'].includes(lang) ? lang : 'de';

export const getLanguage = () => lang;

export const get = () => {
  return getNestedObject(dictionary, [lang]);
};

export const getSection = section => {
  if (!lang || !section) return null;

  return getNestedObject(dictionary, [lang, section]);
};

export const getKey = (section, key) => {
  if (!lang || !section || !key) return null;

  return getNestedObject(dictionary, [lang, section, key]);
};
