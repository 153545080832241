const PLYR_DEFAULTS = {
  speed: { selected: 1, options: [] },
  youtube: {
    noCookie: true,
  },
};

export class Plyr {
  constructor(api, target, options = {}) {
    this.Api = api;
    this.target = target;
    this.options = { ...PLYR_DEFAULTS, options };
    this.player = null;
  }

  create = async () => {
    this.player = new this.Api(this.target, this.options);
    return this.player;
  };
}

export const getApi = async () => {
  const _ = await import(/* webpackChunkName: 'plyr' */ 'plyr');
  return _.default;
};
