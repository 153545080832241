import { $, $$ } from 'utils/dom';
import Accordion from 'modules/accordion';

export class MobileMenu {
  constructor(el) {
    this.el = el;

    this.items = $$('.menu > ul > li');
    this.toggles = $$('.menu-toggle');
    this.accordion = new Accordion($('.menu > ul'), {
      itemSelector: '.menu > ul > li',
      toggleSelector: '.dropdown-toggle',
      bodySelector: '.menu-dropdown',
    });
    console.log('MobileMenu initialized');
  }
}
