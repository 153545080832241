import { addListener, getGlobalOffset, $$ } from 'utils/dom';
import AniScroll from 'modules/aniscroll';

class ScrollToListElement {
  static EventIn = {
    SCROLL_TO: 'ScrollToListElement.EventIn.SCROLL_TO',
  };

  constructor(scrollToPosElem, eventChannelElem, speed = 300) {
    this.mScrollToPosElem = scrollToPosElem;
    this.mEventChannelElem = eventChannelElem;
    this.mSpeed = speed;
  }

  init = () => {
    if (!this.mScrollToPosElem || !this.mEventChannelElem) {
      return;
    }

    this.mAniScroll = new AniScroll({
      elem: this.mScrollToPosElem,
      easing: AniScroll.Easing.EASE_IN_OUT_CUBIC,
    });

    this._events();
  };

  _events = () => {
    addListener(
      this.mEventChannelElem,
      ScrollToListElement.EventIn.SCROLL_TO,
      this._onUpdate
    );
  };

  _isElemInList = elem => {
    if (!this.mScrollToPosElem || !elem) {
      return false;
    }
    const foundElem = $$(':scope > ul > li', this.mScrollToPosElem).filter(
      el => el === elem
    );

    if (foundElem.length !== 1) {
      return false;
    }

    return true;
  };

  _calcScrollLeft = (itemOffset, hostScrollLeft, hostOffsetWidth) => {
    const itemLeft = itemOffset.left;
    const itemRight = itemLeft + itemOffset.width;
    const maxRight = hostOffsetWidth + hostScrollLeft;

    let scrollLeft = hostScrollLeft;
    if (itemRight > maxRight) {
      scrollLeft = itemOffset.left + itemOffset.width - hostOffsetWidth;
    } else if (itemLeft < hostScrollLeft) {
      scrollLeft = itemLeft;
    }

    return scrollLeft;
  };

  _calcScrollTop = (itemOffset, hostScrollTop, hostOffsetHeight) => {
    const itemTop = itemOffset.top;
    const itemBottom = itemTop + itemOffset.height;
    const maxBottom = hostOffsetHeight + hostScrollTop;

    let scrollTop = hostScrollTop;
    if (itemBottom > maxBottom) {
      scrollTop = itemOffset.top + itemOffset.height - hostOffsetHeight;
    } else if (itemTop < hostScrollTop) {
      scrollTop = itemTop;
    }

    return scrollTop;
  };

  _onUpdate = e => {
    const elem = e.detail.elem;
    if (!this._isElemInList(elem)) {
      return;
    }

    const defaultOffset = {
      top: 0,
      left: 0,
    };

    const offset = e.detail.offset
      ? { ...defaultOffset, ...e.detail.offset }
      : { ...defaultOffset };

    const hostElem = this.mScrollToPosElem;
    const itemOffset = getGlobalOffset(elem, this.mScrollToPosElem);

    const hostScrollLeft = hostElem.scrollLeft;
    const scrollLeft = itemOffset.left - offset.left;

    const hostScrollTop = hostElem.scrollTop;
    const scrollTop = itemOffset.top - offset.top;

    if (scrollLeft !== hostScrollLeft || scrollTop !== hostScrollTop) {
      this.mAniScroll.scroll(scrollLeft, scrollTop, this.mSpeed);
    }
  };
}

export default ScrollToListElement;
