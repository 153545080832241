import { $ } from 'utils/dom';
import { LazerLoader } from 'utils/lazer-loader';
import { clamp } from 'utils/helper';

export default class ShowMore {
  constructor(options) {
    this.container = options.container;
    this.btnContainer = options.btnContainer;
    this.items = options.items;
    this.loadMoreBtn = options.loadMoreBtn;
    this.initialImages = options.initialImages || 3;
    this.onclickImages = options.onclickImages || 3;
  }

  loadImages(n, setFocus) {
    const newItems = this.items.splice(0, clamp(n, 0, this.items.length));
    newItems.forEach(item => {
      item.dataset.isHidden = false;
    });
    setFocus && newItems[0].focus();

    new LazerLoader({
      targets: newItems,
      once: true,
      cb: entry => {
        const target = entry.target;
        const pic = $('picture', target);
        const nodes = [...pic.childNodes];

        nodes.forEach(node => {
          if (node.nodeName === 'SOURCE' && node.dataset.srcset) {
            node.setAttribute('srcset', node.dataset.srcset);
            node.removeAttribute('data-srcset');
          }

          if (node.nodeName === 'IMG' && node.dataset.src) {
            node.setAttribute('src', node.dataset.src);
            node.removeAttribute('data-src');
          }
        });
      },
    }).init();

    if (!this.items.length) {
      this.loadMoreBtn && (this.loadMoreBtn.disabled = true);
    }
  }

  bindEvents() {
    this.loadMoreBtn.addEventListener('keydown', e => {
      if (e.key === 'Enter' || e.key === ' ') {
        e.preventDefault();
        this.loadImages(this.onclickImages, true);
      }
    });
    this.loadMoreBtn.addEventListener('click', () =>
      this.loadImages(this.onclickImages)
    );
  }

  init() {
    if (this.loadMoreBtn) {
      this.loadImages(this.initialImages);
      this.bindEvents();
    } else {
      this.loadImages(this.items.length);
    }
  }
}
