import { $$, $, addListener } from '../../../shared/js/utils/dom';
import CalendarScroller from './_calendar-scroller';
import { LoadingAnimation } from '../loading-animation/loading-animation';
import ResultsFetcher from 'modules/results-fetcher';

const defaultParams = {
  hitListElem: null,
  eventChannelElem: null,
  resultsCache: null,
  isSpectrum: false,
};

class ResultsLoader {
  constructor(inParams) {
    const params = { ...defaultParams, ...inParams };

    this.mHitListElem = params.hitListElem;
    this.mEventChannelElem = params.eventChannelElem;
    this.mResultsCache = params.resultsCache ? params.resultsCache : {};
    this.mLoadingAnimation = null;
    this.mResultsFetcher = null;
    this.mIsSpectrum = params.isSpectrum;
  }

  init = () => {
    if (!this.mHitListElem || !this.mEventChannelElem) {
      return;
    }

    this.mLoadingAnimation = new LoadingAnimation(
      this.mHitListElem,
      this.mIsSpectrum
    );
    this.mResultsFetcher = new ResultsFetcher({
      onLoaded: this._onDataReceived,
      onError: error => console.error(error),
    });

    addListener(
      this.mEventChannelElem,
      CalendarScroller.Event.DATE_CHANGED,
      this._onDateChanged
    );
  };

  _onDateChanged = e => {
    const buttonElem = e.detail.elem;
    const timeElem = $(':scope > time', buttonElem);
    const dateStr = timeElem.getAttribute('datetime');
    const htmlStr = this.mResultsCache[dateStr];

    this._clearHitList();
    this.mLoadingAnimation.show();

    // try to get data from the cache
    if (htmlStr) {
      // cancel fetch-request if running currently
      this.mResultsFetcher.cancel();

      this.mLoadingAnimation.hide();
      this._setHitList(htmlStr);
    }
    // try to get data from the endpoint
    else if (buttonElem.dataset.url) {
      this.mResultsFetcher.fetch(buttonElem.dataset.url);
    }
    // endpoint doesn't exist
    else {
      console.error(
        'calendar-teaser:',
        'no data available for the date',
        dateStr
      );
    }
  };

  _onDataReceived = inData => {
    const data = Array.isArray(inData) && inData.length > 0 ? inData[0] : null;

    this.mLoadingAnimation.hide();

    if (data) {
      // update the results list
      this._setHitList(data.indicesHtml);
      // update the cache
      this.mResultsCache[data.date] = data.indicesHtml;
    }
  };

  _clearHitList = () => {
    const elem = this.mHitListElem;
    $$(':scope > .teaser', elem).forEach(childElem =>
      this.mHitListElem.removeChild(childElem)
    );
  };

  _setHitList = htmlStr => {
    const htmlElem = new DOMParser().parseFromString(htmlStr, 'text/html').body;
    const newHitListElem = $('.teaser', htmlElem);

    if (newHitListElem) {
      this.mHitListElem.insertAdjacentHTML(
        'afterbegin',
        newHitListElem.outerHTML
      );
    }
  };
}

export default ResultsLoader;
