import { $, addListener, $$ } from 'utils/dom';
import CheckboxGroup from '../_checkbox-group/checkbox-group';
import DateGroup from '../_date-group/date-group';
import UsedFilterCheckbox from './used-filter-checkbox';
import UsedFilterDate from './used-filter-date';

const defaultOpt = {
  usedFiltersElem: null,
  eventScopeElem: document.documentElement,
};

class UsedFilters {
  static Event = {
    REMOVE_ALL: 'cf-1.used-filters.remove-all',
  };

  constructor(options) {
    const opt = Object.assign(defaultOpt, options);

    this.mEventScopeElem = opt.eventScopeElem;
    this.mUsedFiltersElem = opt.usedFiltersElem;
    this.mClearFilterElem = $('.cf-1__clear-filters', this.mUsedFiltersElem);
    this.mEmptyFilterElem = $('.cf-1__empty-filters', this.mUsedFiltersElem);

    this.mEventScopeElem && this._events();
    this.mEventScopeElem && this._refresh();
  }

  _refresh = () => {
    // send update request to checkboxes
    this.mEventScopeElem.dispatchEvent(
      new CustomEvent(CheckboxGroup.Event.UPDATE_STATE)
    );

    // send update request to date input fields
    this.mEventScopeElem.dispatchEvent(
      new CustomEvent(DateGroup.Event.UPDATE_STATE)
    );
  };

  _events = () => {
    // listen to checkbox state changes and add filter
    addListener(
      this.mEventScopeElem,
      CheckboxGroup.Event.IS_CHANGED,
      this._onCheckboxStateChanged
    );

    // listen to date input changes and create filter (if 'from' or 'to' dates are set)
    addListener(
      this.mEventScopeElem,
      DateGroup.Event.IS_CHANGED,
      this._onDateStateChanged
    );

    // update clear filter button and the 'no filters selected' message
    let eventNames = UsedFilterCheckbox.Event.IS_REMOVED;
    eventNames += ' ' + UsedFilterDate.Event.IS_REMOVED;
    addListener(
      this.mEventScopeElem,
      eventNames,
      this._updateClearBtnAndEmptyFiltersInfo
    );

    // remove all filters by clicking the clear filter button
    const clearFiltersBtnElem = $('button', this.mClearFilterElem);
    addListener(clearFiltersBtnElem, 'click', this._onClearFiltersBtnClick);
  };

  _onClearFiltersBtnClick = () => {
    const event = new CustomEvent(UsedFilters.Event.REMOVE_ALL);
    this.mEventScopeElem.dispatchEvent(event);
  };

  _onCheckboxStateChanged = e => {
    if (e.detail.checked) {
      // create filter element
      const usedFilterCheckbox = new UsedFilterCheckbox({
        name: e.detail.name,
        value: e.detail.value,
        caption: e.detail.caption,
        eventScopeElem: this.mEventScopeElem,
      });

      // append filter element to the document
      const filterElem = usedFilterCheckbox.getElem();
      this._appendFilterToDom(filterElem);

      // update clear filter button and the 'no filters selected' message
      this._updateClearBtnAndEmptyFiltersInfo();
    }
  };

  _onDateStateChanged = e => {
    const dateFromElem = e.detail.dateFromElem;
    const dateToElem = e.detail.dateToElem;

    const dateFromValue = dateFromElem.value;
    const dateToValue = dateToElem.value;

    if (
      (dateFromValue.length || dateToValue.length) &&
      !this._isDateFilterSet()
    ) {
      // create filter element
      const usedFilterDate = new UsedFilterDate({
        dateFromElem,
        dateToElem,
        eventScopeElem: this.mEventScopeElem,
      });

      // append filter element to the document
      const filterElem = usedFilterDate.getElem();
      this._appendFilterToDom(filterElem);

      // update clear filter button and the 'no filters selected' message
      this._updateClearBtnAndEmptyFiltersInfo();
    }
  };

  _appendFilterToDom = filterElem => {
    this.mUsedFiltersElem.insertBefore(filterElem, this.mClearFilterElem);
  };

  _isDateFilterSet = () => {
    return $('li[data-js="date-filter"]', this.mUsedFiltersElem) !== null;
  };

  // update clear filter button and the 'no filters selected' message
  _updateClearBtnAndEmptyFiltersInfo = () => {
    this._updateClearFilterBtn();
    this._updateEmptyFiltersInfo();
  };

  // show clear filter button if there are more then 1 filters selected
  _updateClearFilterBtn = () => {
    const countFilters = $$('li', this.mUsedFiltersElem).length;
    const isVisible = this.mClearFilterElem.classList.contains(
      'cf-1__clear-filters--show'
    );

    if (countFilters > 3 && !isVisible) {
      this.mClearFilterElem.classList.add('cf-1__clear-filters--show');
    } else if (countFilters < 4 && isVisible) {
      this.mClearFilterElem.classList.remove('cf-1__clear-filters--show');
    }
  };

  // show the message that no filters are selected
  _updateEmptyFiltersInfo = () => {
    const countFilters = $$('li', this.mUsedFiltersElem).length;
    const isVisible = this.mEmptyFilterElem.classList.contains(
      'cf-1__empty-filters--hide'
    );

    if (countFilters > 2 && !isVisible) {
      this.mEmptyFilterElem.classList.add('cf-1__empty-filters--hide');
    } else if (countFilters === 2 && isVisible) {
      this.mEmptyFilterElem.classList.remove('cf-1__empty-filters--hide');
    }
  };
}

export default UsedFilters;
