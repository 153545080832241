import { $, $$, insertHTML } from 'utils/dom';
import { uniqueId } from 'utils/helper';
import {
  glideLang,
  glideLabels,
  glideControls,
  generateBullets,
  generatePlayPause,
  generateNumeric,
  generateSliderSkipBackwardsLink,
  generateSliderSkipForwardsLink,
} from './glide-module-template';
import Bus from 'modules/event-bus';

let Glide;

export default class Slider {
  LOADED_CLASS = 'is-initialized';

  constructor(target, props) {
    this.target = target;
    this.config = props.config;
    this.cb = props.cb;
    this.glide = null;
    this.slideLength = 0;
    this.mousePos = null;
    this.sliderID = `slider-${uniqueId()}`;
    this.slides = $$('.glide__slide', this.target);
    this.keyShiftPressed = false;
    this.keyTabPressed = false;
    this.isPaused = !!this.config.autoplay;
  }

  handleCallbacks = () => {
    this.cb && this.cb(this.glide);
  };

  setUpSlider = () => {
    this.glide = new Glide(this.target, this.config);
    // get index of current slide to display in counter
    this.glide.on('move.after', () => {
      this.currentSlide = this.glide.index;
      this.currentSlideIndex = document.querySelector('.glide__numeric-index');
    });

    // update arrow and bullets status after mount and run
    this.glide.on(['mount.after', 'run.after'], () => {
      this.config.controls && this.updateArrowStatus();
      this.config.numeric && this.updateNumeric();
      this.config.bullets && this.updateBullet();
    });

    // remove actual focus to prevent shift bug
    this.glide.on('run.before', () => {
      document.activeElement.blur();
    });

    // re-set animationDuration after slide finished
    // we need this, because our `handleSlideFocus` function sets animationDuration to 0
    // Also re-set focus to active element.
    this.glide.on('run.after', () => {
      this.glide.update({ animationDuration: 400 });
      const activeSlide = this.slides[this.glide.index];
      activeSlide.focus();

      if (this.keyShiftPressed && this.keyTabPressed) {
        const focusableElements =
          activeSlide.querySelectorAll(`a[href]:not([tabindex^="-"]),
        area[href]:not([tabindex^="-"]),
        input:not([type="hidden"]):not([type="radio"]):not([disabled]):not([tabindex^="-"]),
        input[type="radio"]:not([disabled]):not([tabindex^="-"]),
        select:not([disabled]):not([tabindex^="-"]),
        textarea:not([disabled]):not([tabindex^="-"]),
        button:not([disabled]):not([tabindex^="-"]),
        iframe:not([tabindex^="-"]),
        audio[controls]:not([tabindex^="-"]),
        video[controls]:not([tabindex^="-"]),
        [contenteditable]:not([tabindex^="-"]),
        [tabindex]:not([tabindex^="-"])`);
        focusableElements[focusableElements.length - 1].focus();
      }
    });

    // add random id to this.target (slider) container, for a11y
    this.target.id = this.sliderID;

    this.setA11yAttributes();
    this.handleSlideFocus();

    // listen for events in config
    if (this.config.events) {
      this.config.events.forEach(event => {
        this.glide.on(event.event, event.handler);
      });
    }

    // Track key presses of Shift and Tab
    this.glide.selector.addEventListener('keydown', e => {
      if (e.keyCode === 16) {
        this.keyShiftPressed = true;
      }
      if (e.keyCode === 9) {
        this.keyTabPressed = true;
      }
    });
    this.glide.selector.addEventListener('keyup', e => {
      if (e.keyCode === 16) {
        this.keyShiftPressed = false;
      }
      if (e.keyCode === 9) {
        this.keyTabPressed = false;
      }
    });

    /**
     * Add component to extend Glide functionalities
     *
     * @param {Glide} Glide
     * @param {Object} Components
     * @param {EventsBus} Events
     */
    const self = this;
    const Components = (Glide, Components, Events) => {
      return {
        mount() {
          // get slide length
          self.slideLength =
            self.config.numericAmount || Components.Sizes.length;
          self.config.numeric && self.createNumeric(self.slideLength);

          self.createSkipForwardsLink();
          self.createSkipBackwardsLink();
          self.addSkipLinksEvents();
        },
      };
    };

    this.initSlider(Components);
  };

  initSlider = Components => {
    this.glide.mount({ Components });

    this.handleCallbacks();
  };

  updateArrowStatus = () => {
    const arrowLeft = $('.glide__controls .glide__arrow--left', this.target);
    const arrowRight = $('.glide__controls .glide__arrow--right', this.target);
    const type = this.glide.type;
    const index = this.glide.index + 1;

    /*
     * If type slider, catch if the active slide is the first or the last slide
     * so we can disable the Button
     * */
    if (type === 'slider') {
      if (index === 1) {
        arrowRight.removeAttribute('disabled');
        arrowLeft.setAttribute('disabled', 'true');
      } else if (index === this.slideLength) {
        arrowLeft.removeAttribute('disabled');
        arrowRight.setAttribute('disabled', 'true');
      } else {
        arrowLeft.removeAttribute('disabled');
        arrowRight.removeAttribute('disabled');
      }
    }
  };

  handlePerViewChange = () => {
    const controls = $('.glide__controls', this.target);
    if (controls) {
      const arrowLeft = $('.arrow-left', controls);
      const arrowRight = $('.arrow-right', controls);

      Object.entries(this.config.breakpoints)
        .reverse()
        .forEach(([key, value]) => {
          if (value.perView && window.innerWidth <= key) {
            const perviewLeft =
              value.perView === 1 ? '<' : `=${value.perView - value.perView}`;
            const perviewRight =
              value.perView === 1 ? '>' : `=${value.perView}`;

            arrowLeft && arrowLeft.setAttribute('data-glide-dir', perviewLeft);
            arrowRight &&
              arrowRight.setAttribute('data-glide-dir', perviewRight);
          }
        });
    }
  };

  setA11yAttributes = () => {
    this.slides.forEach(slide => slide.setAttribute('tabindex', '0'));
  };

  removeA11yAttributes = () => {
    this.slides.forEach(slide => slide.removeAttribute('tabindex'));
  };

  createBullets = () => {
    const createBulletsTemplate = generateBullets(this.slides, this.sliderID);
    insertHTML(this.target, 'beforeend', createBulletsTemplate);

    const bullets = $$('.glide__bullet', this.target);

    bullets.forEach(bullet => {
      bullet.addEventListener('click', () => {
        this.glide.go(bullet.dataset.glideDir);
        this.updateBullet();
      });

      bullet.addEventListener('keyup', event => {
        // check if user used enter or space key, if so => shift focus to active slide
        if (event.keyCode === 13 || event.keyCode === 32) {
          const targetIndex = bullet.dataset.glideDir.replace('=', '');
          const targetSlide = this.slides[+targetIndex];

          // we need to set tabindex, because per default you can't focus divs or lis
          targetSlide.setAttribute('tabindex', '0');
          targetSlide.focus();
        }
      });
    });
  };

  createPlayPause = () => {
    const createPlayPauseTemplate = generatePlayPause(this.sliderID);
    insertHTML(this.target, 'beforeend', createPlayPauseTemplate);
    this.updatePlayPause();
    const playPause = $('.glide__play', this.target);

    playPause.addEventListener('click', () => {
      this.togglePlayPause();
    });

    playPause.addEventListener('keyup', event => {
      // check if user used enter or space key, if so => shift focus to active slide
      if (event.keyCode === 13 || event.keyCode === 32) {
        this.togglePlayPause();
        console.log('enter');
      }
    });
  };

  togglePlayPause = () => {
    this.isPaused = !this.isPaused;
    this.updatePlayPause();
    // TODO: autoplay true is neccessary for .play and .pause and you shoudnt toggle it like this but instead use play and pause methods
    this.glide.update({ autoplay: this.isPaused ? 8000 : false });
  };

  createControls = () => {
    insertHTML(this.target, 'beforeend', glideControls(this.sliderID));

    const arrows = $$('.glide__arrow', this.target);
    arrows.forEach(arrow => {
      arrow.addEventListener('keyup', event => {
        // check if user used enter or space key, if so => shift focus to active slide
        if (event.keyCode === 13 || event.keyCode === 32) {
          const targetSlide = this.slides[this.glide.index];

          // we need to set tabindex, because per default you can't focus divs or lis
          targetSlide.setAttribute('tabindex', '0');
          targetSlide.focus();
        }
      });
    });

    if (this.config.breakpoints) {
      this.handlePerViewChange();
    }
  };

  createNumeric = slidesLength => {
    const numericTemplate = generateNumeric(slidesLength);
    insertHTML(this.target, 'beforeend', numericTemplate);
  };

  updateBullet = () => {
    const bullets = $$('.glide__bullet', this.target);

    // reset active class
    bullets.forEach(bullet => {
      bullet.classList.remove('glide__bullet--active');
      bullet.querySelector('.glide__bullet-current-text').innerHTML = '';
    });

    // set active class to active index
    bullets[this.glide.index].classList.add('glide__bullet--active');
    // set current active slide to bullet for accsibility
    bullets[this.glide.index].querySelector(
      '.glide__bullet-current-text'
    ).innerHTML = glideLabels.current[glideLang];
  };

  updatePlayPause = () => {
    const playPause = $('.glide__play', this.target);

    if (this.isPaused) {
      playPause.classList.add('is-paused');
    } else {
      playPause.classList.remove('is-paused');
    }
  };

  updateNumeric = () => {
    $('.glide__numeric-index', this.target).innerText = this.glide.index + 1;
  };

  addSkipLinksEvents = () => {
    const skipLinks = $$('.glide__skip-link', this.target);
    skipLinks.forEach(skipLink => {
      skipLink.addEventListener('click', e => {
        e.preventDefault();
        const target = skipLink.dataset.target;

        if (target === 'slide') {
          const activeSlide = $('.glide__slide--active', this.target);

          // we need to set tabindex, because per default you can't focus divs or lis
          activeSlide.setAttribute('tabindex', '0');
          activeSlide.focus();
        }
        if (target === 'end') {
          $(`#${this.sliderID}-skiplink-end`).focus();
        }
        if (target === 'start') {
          $(`#${this.sliderID}-skiplink-start`).focus();
        }
      });
    });
  };

  createSkipForwardsLink = () => {
    const skipLinkTemplate = generateSliderSkipForwardsLink(this.sliderID);
    insertHTML(this.target, 'afterbegin', skipLinkTemplate);
  };

  createSkipBackwardsLink = () => {
    const skipLinkTemplate = generateSliderSkipBackwardsLink(this.sliderID);
    insertHTML(this.target, 'beforeend', skipLinkTemplate);
  };

  // When a slide (or its children) receives focus, tell glide to slide to that slide
  handleSlideFocus = () => {
    let lastFocusedSlide = null;

    this.slides.forEach((slide, index) => {
      slide.addEventListener('focus', () => {
        if (lastFocusedSlide && slide !== lastFocusedSlide) {
          // set animationDuration to 0 prevent focus shift bug
          this.glide.update({ animationDuration: 0 });
          this.glide.go(`=${index}`);
        }
        lastFocusedSlide = slide;
      });

      slide.addEventListener('blur', () => {
        // IF NOT Backwards focus tabbing
        if (!(this.keyShiftPressed && this.keyTabPressed)) {
          return;
        }
        // IF first slide
        if (index === 0) {
          return;
        }
        // set animationDuration to 0 prevent focus shift bug
        this.glide.update({ animationDuration: 0 });
        this.glide.go(`=${index - 1}`);
      });
    });
  };

  getMousePosition = e => {
    this.mousePos = {
      clientX: e.clientX,
      clientY: e.clientY,
    };
  };

  destroy = () => {
    if (!this.glide) return;

    const bullets = $('.glide__bullets', this.target);
    const controls = $('.glide__controls', this.target);
    const numeric = $('.glide__numeric', this.target);
    const skipLinks = $$('.glide__skip-link', this.target);
    const skipLinkTarget = $(
      '.glide__skip-link-target',
      this.target.parentElement
    );

    bullets && bullets.parentNode.removeChild(bullets);
    controls && controls.parentNode.removeChild(controls);
    numeric && numeric.parentNode.removeChild(numeric);

    if (skipLinks.length) {
      skipLinks.forEach(skipLink => skipLink.parentNode.removeChild(skipLink));
    }

    skipLinkTarget && skipLinkTarget.parentNode.removeChild(skipLinkTarget);

    this.target.classList.remove(this.LOADED_CLASS);

    this.removeA11yAttributes();

    this.glide.destroy();
  };

  handleResize = () => {
    if (this.glide && this.config.breakpoints) {
      this.handlePerViewChange();
    }
  };

  init = () => {
    import(/* webpackChunkName: '@glidejs/glide' */ '@glidejs/glide').then(
      _ => {
        Glide = _.default;

        this.config.bullets && this.createBullets();
        this.config.controls && this.createControls();
        this.config.togglePlay && this.createPlayPause();

        this.setUpSlider();

        // Prevents non-styled layout before js is loaded
        // Make sure to hide slider without LOADED_CLASS via CSS
        this.target.classList.add(this.LOADED_CLASS);

        Bus.subscribe('resize', 'GlideSlider', this.handleResize);
      }
    );
  };
}
