import { addListener, closest } from 'utils/dom';
import DropdownMenu from './_dropdown-menu/dropdown-menu';
import DatePicker from './_datepicker/datepicker';

class MainController {
  constructor(eventScopeElem) {
    this.mEventScopeElem = eventScopeElem;
    this.mEventScopeElem && this._events();
  }

  _events = () => {
    addListener(document.documentElement, 'mousedown', this._onClick);
    addListener(this.mEventScopeElem, 'keyup', e => {
      // eslint-disable-next-line default-case
      switch (e.keyCode) {
        // close the dropdown menus when 'esc' key is pressed
        case 27:
          this._closeAllDropdownMenus();
          break;

        // close the date picker when 'enter' key is pressed
        case 13:
          this._closeAllDatePicker();
          break;
      }
    });
  };

  _onClick = e => {
    // stop here if the click event was triggered inside a dropdown menu
    const dropdownMenuElem = closest(e.target, '.ddm-1');
    if (dropdownMenuElem) return;

    // stop here if the click event was triggered inside a date picker
    const datePickerElem = closest(e.target, '.cf-1__date-picker-container');
    if (datePickerElem) return;

    this._closeAllDropdownMenus();
  };

  _closeAllDropdownMenus = () => {
    const event = new CustomEvent(DropdownMenu.Event.CLOSE_ALL);
    this.mEventScopeElem.dispatchEvent(event);
  };

  _closeAllDatePicker = () => {
    const event = new CustomEvent(DatePicker.Event.CLOSE_ALL);
    this.mEventScopeElem.dispatchEvent(event);
  };
}

export default MainController;
