import { addStyles } from 'utils/dom';

const loadingAnimationTemplate = `
<div class="loading-animation loading-animation--tm is-running">
    <div class="rect"></div>
</div>
`;

const loadingAnimationTemplateSpectrum = `
<div class="loading-animation loading-animation--spectrum is-running">
    <div class="rect"></div>
</div>
`;

class LoadingAnimation {
  constructor(containerElem, isSpectrum = false, customStyles) {
    this.mContainerElem = containerElem;
    this.mLoadingAnimElem = undefined;
    this.mIsSpectrum = isSpectrum;
    this.mCustomStyles = customStyles;
  }

  show = () => {
    if (this.mLoadingAnimElem || !this.mContainerElem) {
      return;
    }

    const divElem = document.createElement('div');
    divElem.innerHTML = this.mIsSpectrum
      ? loadingAnimationTemplateSpectrum
      : loadingAnimationTemplate;
    this.mLoadingAnimElem = divElem.firstElementChild;

    // set styles
    if (!this.mCustomStyles) {
      addStyles(this.mLoadingAnimElem, {
        position: 'absolute',
        top: 'calc(50% - 0.5em)',
        left: 'calc(50% - 0.5em)',
      });
    } else {
      addStyles(this.mLoadingAnimElem, this.mCustomStyles);
    }

    this.mContainerElem.appendChild(this.mLoadingAnimElem);
  };

  hide = () => {
    if (!this.mLoadingAnimElem || !this.mContainerElem) {
      return;
    }

    this.mContainerElem.removeChild(this.mLoadingAnimElem);
    this.mLoadingAnimElem = null;
  };

  getElem = () => {
    return this.mLoadingAnimElem;
  };
}

export {
  loadingAnimationTemplate,
  loadingAnimationTemplateSpectrum,
  LoadingAnimation,
};
