import { $, $$, closest, insertHTML, remove } from 'utils/dom';
import TEMPLATE from './lightbox-template';
import './Lightbox.scss';
import './skin.scss';
import { uniqueId } from 'utils/helper';

export default class Lightbox {
  TEMPLATE_SELECTOR = '.pswp';
  CAPTION_SELECTOR = '.pswp__caption:not(.pswp__caption--fake)';
  SCROLLWRAP_SELECTOR = '.pswp__scroll-wrap';
  // SCROLLWRAP_SELECTOR = '.pswp__scroll-wrap';
  CAPTION_TOGGLE_BTN = '.pswp-toggle-caption';

  constructor(container, options) {
    this.container = container;
    this.selector = options.selector;
    this.theme = options.theme;

    this.templateIsAdded = this.isTemplateAdded();
    this.template = this.templateIsAdded
      ? $(this.TEMPLATE_SELECTOR)
      : new DOMParser().parseFromString(TEMPLATE, 'text/html').body.firstChild;
    this.template.classList.add(`pswp--${this.theme}`);
    !this.templateIsAdded && document.body.appendChild(this.template);

    this.config = {
      timeToIdle: 4000,
      timeToIdleOutside: 1000,
      loadingIndicatorDelay: 1000,
      index: 0,
      bgOpacity: 1,
      showHideOpacity: true,
      history: false,
      closeOnScroll: false,
      zoomEl: false, // hide the zoom icon
    };
    this.items = $$(this.selector, this.container);
    this.slides = this.getSlides(this.items);
    this.pswp = null;
    this.isZoomedIn = false;
    this.captionState = false;
  }

  isTemplateAdded = () => $(this.TEMPLATE_SELECTOR);

  getSlides = items => {
    return items.map(item => {
      const dataset = item.dataset;
      const { src, size, title } = dataset;
      const sizes = size.split('x');
      const w = parseInt(sizes[0], 10);
      const h = parseInt(sizes[1], 10);
      const figcaption = item.nextElementSibling;
      const caption = figcaption ? figcaption.innerHTML.trim() : false;
      return { src, w, h, title: caption, imageTitle: title };
    });
  };

  bindEvents = () => {
    this.items.forEach(item => item.addEventListener('click', this.open));
  };

  getIndex = slide => {
    switch (typeof slide) {
      case 'number':
        return slide;
      case 'object':
        return this.items.indexOf(closest(slide.target, this.selector));
      default:
        return null;
    }
  };

  setImageTitle = item => {
    document.getElementById('pswp_imagetitle').textContent = item.imageTitle;
  };

  cloneCaption = () => {
    /*
     * User cant select the caption text, because it will always trigger the swipe event.
     * So we clone the caption and set it outside of the swipe-element.
     * */

    const captionId = uniqueId();
    const cloneClass = 'pswp--is-clone';
    const scrollWrap = $(this.SCROLLWRAP_SELECTOR);
    const caption = $(
      `${this.SCROLLWRAP_SELECTOR} ${this.CAPTION_SELECTOR}`,
      this.template
    );
    const clone = caption.cloneNode(true);
    clone.classList.add(cloneClass);

    // remove old caption
    const oldClone = $(`.${cloneClass}`, this.template);
    oldClone && remove(oldClone);

    // clone active one
    scrollWrap && insertHTML(scrollWrap, 'beforebegin', clone.outerHTML);

    // init toggle function for captionText
    const captionToggleBtn = $(this.CAPTION_TOGGLE_BTN, this.template);
    if (captionToggleBtn) {
      const parent = captionToggleBtn.parentElement;
      const cloneTextSelector = '.pswp--caption-text';
      const captionText = $(cloneTextSelector, parent);

      if (captionText) {
        captionText.id = `caption-${captionId}`;
        captionText.setAttribute('aria-labelledby', `btn-${captionId}`);
        captionText.setAttribute('aria-hidden', `${!this.captionState}`);
      } else {
        remove(captionToggleBtn);
      }

      captionToggleBtn.id = `btn-${captionId}`;
      captionToggleBtn.setAttribute('aria-controls', `caption-${captionId}`);
      captionToggleBtn.setAttribute('aria-expanded', `${this.captionState}`);
      captionToggleBtn.addEventListener('click', () =>
        this.toggleCaption(captionToggleBtn)
      );
    }
  };

  toggleCaption = btn => {
    const target = $(`#${btn.getAttribute('aria-controls')}`);

    if (target) {
      if (target.getAttribute('aria-hidden') === 'true') {
        this.captionState = true;
        target.setAttribute('aria-hidden', 'false');
      } else {
        this.captionState = false;
        target.setAttribute('aria-hidden', 'true');
      }
    }
  };

  // `slide` could be an event or an index
  open = slide => {
    const { template, slides, config } = this;
    // Opens selected slide
    config.index = this.getIndex(slide);

    if (config.index !== -1) {
      return Promise.all([
        import(/* webpackChunkName: 'photoswipe' */ 'photoswipe'),
        import(
          /* webpackChunkName: 'PhotoSwipeUI' */ 'photoswipe/dist/photoswipe-ui-default'
        ),
      ]).then(([ps, psui]) => {
        const PhotoSwipe = ps.default;
        const PhotoSwipeUI = psui.default;

        this.pswp = new PhotoSwipe(template, PhotoSwipeUI, slides, config);

        // don't zoom when clicking the image
        this.pswp.toggleDesktopZoom = () => {};

        this.pswp.init();
        this.setImageTitle(this.pswp.currItem);
        this.cloneCaption();

        this.pswp.listen('afterChange', () => {
          this.setImageTitle(this.pswp.currItem);
          this.cloneCaption();
        });

        new MutationObserver(mutations => {
          // eslint-disable-next-line array-callback-return
          mutations.some(mutation => {
            // eslint-disable-next-line array-callback-return
            if (mutation.attributeName !== 'class') return;

            const hasZoomClass = Array.from(this.template.classList).includes(
              'pswp--zoomed-in'
            );
            if (hasZoomClass && !this.isZoomedIn) {
              this.pswp.ui.hideControls();
              this.isZoomedIn = true;
              return true;
            } else if (!hasZoomClass && this.isZoomedIn) {
              this.pswp.ui.showControls();
              this.isZoomedIn = false;
              return true;
            }
          });
        }).observe(this.template, { attributes: true });
      });
    } else {
      return Promise.resolve();
    }
  };

  init = () => {
    this.bindEvents();
  };
}
