import DatePickerOptions from 'tiny-date-picker/src/date-picker-options';
import Mode from 'tiny-date-picker/src/mode/index';
import Emitter from 'tiny-date-picker/src/lib/emitter';

export default function TinyDatePicker(input, opts) {
  const emitter = Emitter();
  const options = DatePickerOptions(opts);
  const mode = Mode(input, emit, options);
  const me = {
    get state() {
      return mode.state;
    },
    on: emitter.on,
    off: emitter.off,
    setState: mode.setState,
    open: mode.open,
    close: mode.close,
    destroy: mode.destroy,
    mode /* custom option */,
  };

  function emit(evt) {
    emitter.emit(evt, me);
  }

  return me;
}
