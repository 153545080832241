import { $ } from 'utils/dom';

export class ImageWithCaption {
  constructor(el) {
    this.el = el;
    this.toggle = $('[data-toggle]', this.el);

    this.toggle &&
      this.toggle.addEventListener('click', this.onToggle.bind(this));
  }

  onToggle() {
    this.el.classList.toggle('is-active');
  }
}
