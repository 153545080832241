import { $, $$ } from 'utils/dom';
import Slider from 'modules/glide/glide';

const sliderWrapper = $('.page-header-slider__slider');

if (sliderWrapper) {
  let activeIndex = 0;

  const slider = new Slider(sliderWrapper, {
    config: {
      type: 'slider',
      gap: 0,
      autoplay: false,
      animationTimingFunc: 'cubic-bezier(0.55, 0, 0.25, 1)',
      animationDuration: 1500,
      swipeThreshold: 40,
      dragThreshold: 40,
      bullets: true,
      numeric: true,
      togglePlay: true,
      events: [
        {
          event: ['mount.after', 'run.after'],
          handler: () => {
            const index = slider.glide.index;
            const bullets = $$('.glide__bullet', sliderWrapper);
            const headlines = $$('.page-header-slider__headline');

            activeIndex = index;

            // reset other bullets
            bullets.forEach(otherBullet =>
              otherBullet.classList.remove('glide__bullet--active')
            );

            // set active bullet
            bullets[index].classList.add('glide__bullet--active');

            // add attribute to slide headline in
            setTimeout(() => {
              headlines[index].setAttribute('data-headline-in', '');
            }, 800);

            // remove atrribute for sliding headline out
            headlines.forEach(headline => {
              headline.removeAttribute('data-headline-out');
            });

            // remove attribute to slide headline in
            const headlineIn = $('[data-headline-in');

            if (headlineIn) {
              headlineIn.removeAttribute('data-headline-in');
            }

            // check if user clicks on bullet which jumps multiple slides to hide headlines
            bullets.forEach(bullet => {
              bullet.addEventListener('click', () => {
                const bulletIndex = bullet
                  .getAttribute('data-glide-dir')
                  .charAt(1);

                const difference = Math.abs(bulletIndex - activeIndex);

                if (difference > 1) {
                  headlines.forEach(headline => {
                    headline.setAttribute('data-headline-hidden', '');
                  });
                }
              });
            });

            // remove hidden attribute after timeout
            setTimeout(() => {
              const hiddenHeadlines = $$('[data-headline-hidden]');
              if (hiddenHeadlines.length) {
                hiddenHeadlines.forEach(hiddenHeadline => {
                  hiddenHeadline.removeAttribute('data-headline-hidden');
                });
              }
            }, 800);
          },
        },
        {
          event: ['run.before'],
          handler: () => {
            const activeHeadline = $('.glide__slide--active [data-headline-in');
            const headlines = $$('.page-header-slider__headline');

            // add attribute to slide headline out
            if (activeHeadline) {
              activeHeadline.setAttribute('data-headline-out', '');
            }

            const hiddenHeadlines = $$('[data-headline-hidden]');

            // remove all hidden attributes
            if (hiddenHeadlines.length) {
              hiddenHeadlines.forEach(hiddenHeadline => {
                hiddenHeadline.removeAttribute('data-headline-hidden');
              });
            }

            const currentBullet = $('.glide__bullet--active')
              .getAttribute('data-glide-dir')
              .charAt(1);

            // check if next slide is the first slide (is current bullet the last?)
            if (parseInt(currentBullet, 10) === slider.slideLength - 1) {
              headlines.forEach(headline => {
                headline.setAttribute('data-headline-hidden', '');
              });
            }
          },
        },
      ],
    },
  });

  slider.init();

  const setHeightForVideo = () => {
    let sliderHeight = $('.page-header-slider').offsetHeight;
    const videoWrapper = $('.page-header-slider__video');
    if (!videoWrapper) {
      return;
    }

    if (window.innerWidth <= 1380) {
      sliderHeight = sliderHeight - 9;
      videoWrapper.style.height = sliderHeight + 'px';
    } else if (window.innerWidth > 1380) {
      sliderHeight = sliderHeight - 9 + 150;
    }
  };

  setTimeout(setHeightForVideo, 100);
  window.addEventListener('resize', setHeightForVideo);
}
