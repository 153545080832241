import {
  addListener,
  dispatchEvent,
  closestToParent,
  removeListener,
} from 'utils/dom';

class DragScroll {
  static EventOut = {
    DRAG_END: 'dragscroll.event-out.drag-end',
  };

  constructor(dragscrollElem, eventChannelElem) {
    this.mDragScrollElem = dragscrollElem;
    this.mEventChannelElem = eventChannelElem;

    this.mPushed = false;
    this.mMouseMoving = false;
    this.mMouseMovingTimeoutId = undefined;

    this.mLastClientX = undefined;
    this.mLastClientY = undefined;

    this.mPushedElem = undefined;
  }

  init = () => {
    addListener(this.mDragScrollElem, 'click', this.checkFocusable, true);

    addListener(
      this.mDragScrollElem,
      'mousedown touchstart',
      this._onMouseDown,
      true
    );

    addListener(this.mDragScrollElem, 'mousemove touchmove', this._onMouseMove);
    addListener(
      this.mDragScrollElem,
      'mouseup touchend',
      this._onMouseUp,
      true
    );
    addListener(
      document.documentElement,
      'mouseleave mouseout',
      this.checkMouseUpTarget
    );
  };

  destroy = () => {
    removeListener(this.mDragScrollElem, 'click', this.checkFocusable, true);

    removeListener(
      this.mDragScrollElem,
      'mousedown touchstart',
      this._onMouseDown,
      true
    );

    removeListener(
      this.mDragScrollElem,
      'mousemove touchmove',
      this._onMouseMove
    );
    removeListener(
      this.mDragScrollElem,
      'mouseup touchend',
      this._onMouseUp,
      true
    );
    removeListener(
      document.documentElement,
      'mouseleave mouseout',
      this.checkMouseUpTarget
    );
  };

  checkFocusable = e => {
    if (!this.mPushedElem) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      // if the clicked element is focusable or is nested in one, performe focus
      const focusableElem = this._findFocusableElem(this.mPushedElem);
      focusableElem && focusableElem.focus();

      this.mPushedElem = null;
    }
  };

  checkMouseUpTarget = e => {
    if (e.target === document.documentElement && this.mPushed) {
      this._onMouseUp(e);
    }
  };

  _findFocusableElem = target => {
    let focusableElem = null;

    ['a', 'button', 'input'].forEach(selector => {
      if (!focusableElem) {
        focusableElem = closestToParent(target, selector, this.mDragScrollElem);
      }
    });

    return focusableElem;
  };

  _setMouseMovingOn = delay => {
    if (!this.mMouseMoving && !this.mMouseMovingTimeoutId) {
      this.mMouseMovingTimeoutId = setTimeout(() => {
        this.mMouseMoving = true;
        this.mMouseMovingTimeoutId = null;
      }, delay);
    }
  };

  _onMouseDown = e => {
    if (e.cancelable) {
      e.preventDefault();
      this.mPushedElem = e.target;

      // handle mousedown events
      if (e.type.toLowerCase() === 'mousedown') {
        this.mLastClientX = e.pageX;
        this.mLastClientY = e.pageY;

        // only handle the mouse left button
        if (e.button !== 0) return;
      }
      // handle touchstart event
      else {
        this.mLastClientX = e.changedTouches[0].pageX;
        this.mLastClientY = e.changedTouches[0].pageY;
      }

      this.mPushed = true;
      this.mMouseMoving = false;
    }
  };

  _onMouseMove = e => {
    if (!this.mPushed) {
      return;
    }

    this._setMouseMovingOn(70);

    let clientX, clientY;

    // handle mousemove events
    if (e.type.toLowerCase() === 'mousemove') {
      clientX = e.pageX;
      clientY = e.pageY;
    }
    // handle touchmove event
    else {
      clientX = e.changedTouches[0].pageX;
      clientY = e.changedTouches[0].pageY;
    }

    if (this.mLastClientX !== clientX) {
      this.mDragScrollElem.scrollLeft += this.mLastClientX - clientX;
      this.mLastClientX = clientX;
    }

    if (this.mLastClientY !== clientY) {
      this.mDragScrollElem.scrollTop += this.mLastClientY - clientY;
      this.mLastClientY = clientY;
    }
  };

  _onMouseUp = e => {
    if (e.cancelable) {
      e.preventDefault();

      if (this.mPushed) {
        if (!this.mMouseMoving && this.mPushedElem) {
          this.mPushedElem.click();
        }

        this.mEventChannelElem &&
          dispatchEvent(this.mEventChannelElem, DragScroll.EventOut.DRAG_END);
      }

      this.mPushed = false;
      this.mMouseMoving = false;
      this.mPushedElem = null;
    }
  };
}

export default DragScroll;
