import { $, $$ } from 'utils/dom';
import Shower from '../../../shared/js/modules/shower';

export class Accordion {
  constructor(container) {
    this.container = container;
    this.items = $$('.accordion--item', this.container);
    this.container && this.init();
  }

  initShower = () => {
    this.items.forEach(item => {
      const target = $('.accordion--body', item);
      const handler = $('.accordion--btn', item);

      return new Shower({
        target,
        handler,
        easing: 'easeInOutQuart',
        duration: 500,
        initOpened: false,
        openClassName: 'is-open',
      }).init();
    });
  };

  init = () => {
    this.items.length && this.initShower();
  };
}
