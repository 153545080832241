import { $, $$ } from 'utils/dom';
import axios from 'axios';
import { LoadingAnimation } from '../loading-animation/loading-animation';

const $$loadMoreBtn = $$('.load-more-news');
const isSpectrum = $('body.spectrum');

class LoadMore {
  constructor() {
    this.loadMoreBtn = $('.load-more-news');
    this.list = $('.inner.list');
    this.url = this.loadMoreBtn.getAttribute('data-uri');
    this.pagination = $$('.page-navigation');
    this.isSpectrum = false;
    this.customStyles = {
      position: 'absolute',
      top: 'calc(100% + 0.5em)',
      left: 'calc(50% - 0.5em)',
    };
  }

  init(isSpectrum) {
    this.loadingAnimation = new LoadingAnimation(
      this.pagination[0],
      isSpectrum,
      this.customStyles
    );
    this.loadMoreBtn.addEventListener('click', () =>
      this.loadMore(this.url, isSpectrum)
    );
  }

  loadMore(url, isSpectrum) {
    this.hideBtn();
    this.loadingAnimation.show();
    const ajaxUrl = `${window.location.protocol}//${window.location.host}${url}`;
    axios.get(ajaxUrl).then(response => {
      const parser = new DOMParser();
      const parsedHtml = parser.parseFromString(response.data, 'text/html');
      const nextItems = parsedHtml.querySelector('.inner.list').innerHTML;
      this.loadingAnimation.hide();
      this.list.insertAdjacentHTML('beforeend', nextItems);
      this.loadMoreAgain(isSpectrum);
    });
  }

  hideBtn() {
    const loadMoreContainer = $('.text-center');
    loadMoreContainer.remove();
  }

  loadMoreAgain(isSpectrum) {
    const newLoadMoreBtn = $('.load-more-news');
    const newPagination = $$('.page-navigation');
    if (newLoadMoreBtn !== null) {
      const newAjaxUrl = newLoadMoreBtn.getAttribute('data-uri');
      this.loadingAnimation = new LoadingAnimation(
        newPagination[newPagination.length - 1],
        isSpectrum,
        this.customStyles
      );
      newLoadMoreBtn.addEventListener('click', () => this.loadMore(newAjaxUrl));
    }
  }
}
$$loadMoreBtn.length && new LoadMore().init(isSpectrum);
