import { $, $$ } from 'utils/dom';

export class CalendarDetails {
  MAX_FUTURE_INDICES = 5;

  constructor(element) {
    this.indicesContainer = $('.ems-other-indices', element);
    this.indicesLabel = $('.ems-other-indices-label', element);
    this.otherIndexItems = $$('.ems-other-index', element);
    this.init();
  }

  init() {
    if (this.indicesContainer) {
      this.updateIndex();
      window.addEventListener('hashchange', this.updateIndex.bind(this));
      this.initOtherIndexItemsLinkHandler();
    }
  }

  updateIndex() {
    const indexItem = this.getIndexItemFromLocationHash();
    if (indexItem) {
      this.replaceDateText(indexItem);
      this.replaceTimeText(indexItem);
      this.replaceStateText(indexItem);
      this.replaceIcsUrl(indexItem);
    }
    this.showOtherFutureIndices(indexItem);
  }

  getIndexItemFromLocationHash() {
    const dateFromURL = window.location.hash.substring(1);
    return this.otherIndexItems.filter(
      el => el.dataset.start === dateFromURL
    )[0];
  }

  replaceDateText(indexItem) {
    const eventDateTarget = $('[data-date-text-target]');
    if (eventDateTarget && indexItem.dataset.dateText !== undefined) {
      eventDateTarget.innerHTML = this.decodeHtml(indexItem.dataset.dateText);
    }
  }

  replaceTimeText(indexItem) {
    const eventTimeTarget = $('[data-time-text-target]');
    if (eventTimeTarget && indexItem.dataset.timeText !== undefined) {
      eventTimeTarget.innerHTML = this.decodeHtml(indexItem.dataset.timeText);
    }
  }

  replaceStateText(indexItem) {
    const eventStateTarget = $('[data-state-text-target]');
    if (eventStateTarget && indexItem.dataset.stateText !== undefined) {
      eventStateTarget.innerHTML = this.decodeHtml(indexItem.dataset.stateText);
    }
  }

  decodeHtml(string) {
    return new DOMParser().parseFromString(string, 'text/html').body.innerHTML;
  }

  replaceIcsUrl(indexItem) {
    const eventIcsTarget = $('[data-ics-url-target]');
    if (eventIcsTarget) {
      if (indexItem.dataset.icsUrl !== undefined) {
        eventIcsTarget.href = indexItem.dataset.icsUrl;
        eventIcsTarget.hidden = false;
      } else {
        eventIcsTarget.hidden = true;
      }
    }
  }

  showOtherFutureIndices(currentIndexItem) {
    let count = 0;
    this.otherIndexItems.forEach(indexItem => {
      const indexDate = new Date(indexItem.dataset.start);
      if (
        count < this.MAX_FUTURE_INDICES &&
        currentIndexItem !== indexItem &&
        indexDate.getTime() > Date.now()
      ) {
        indexItem.hidden = false;
        ++count;
      } else {
        indexItem.hidden = true;
      }
    });
    const isEveryIndexHidden = this.otherIndexItems.every(
      indexItem => indexItem.hidden
    );
    this.indicesContainer.hidden = isEveryIndexHidden;
    this.indicesLabel.hidden = isEveryIndexHidden;
  }

  initOtherIndexItemsLinkHandler() {
    $$('.ems-other-index a[href^="#"]').forEach(link => {
      link.addEventListener('click', () => {
        window.scrollTo(0, 0);
      });
    });
  }
}
