import { $, $$, addListener, remove } from 'utils/dom';
import * as Translation from 'utils/translation';
import { LoadingAnimation } from '../loading-animation/loading-animation';
import ResultsFetcher from './_results-fetcher';
import { CalendarFilter } from '../calendar-filter/calendar-filter';

class ResultsLoader {
  constructor(calendarElem) {
    this.mCalendarElem = calendarElem;
    this.mCalendarFilterElem = null;
    this.mCalendarResultsElem = null;

    this.mLoadingAnimation = null;

    this.mTranslation = Translation.getSection('calendar-filter');
  }

  init = () => {
    if (!this.mCalendarElem) return;

    this.mCalendarFilterElem = $('.calendar-filter', this.mCalendarElem);

    this.mCalendarResultsElem = $(
      ':scope > section.calendar__results',
      this.mCalendarElem
    );

    if (!this.mCalendarFilterElem || !this.mCalendarResultsElem) return;

    this.mLoadingAnimation = new LoadingAnimation(
      this.mCalendarResultsElem,
      false,
      {
        position: 'absolute',
        left: 'calc(50% - 0.5em)',
        bottom: '-200px',
      }
    );

    this._events();
  };

  _events = () => {
    addListener(this.mCalendarElem, ResultsFetcher.EventOut.LOADING, e => {
      const filters = e.detail && e.detail.filters;

      if (filters) {
        this._clearResultsInfo();
        this._deleteHitList();
      }

      this._deleteLoadMoreBtn();
      this._showLoadingAnim();
    });

    addListener(this.mCalendarElem, ResultsFetcher.EventOut.LOADED, e => {
      const response = this._parseResponse(e.detail);

      this._setResultsInfo(response.resultsInfoElem);
      this._hideLoadingAnim();
      this._setHitList(response.firstElem);
      response.loadMoreBtnElem &&
        this._setLoadMoreBtn(
          response.resultsInfoElem,
          response.loadMoreBtnElem
        );
    });
  };

  _parseResponse = htmlStr => {
    const response = {};

    const responseBodyElem = new DOMParser().parseFromString(
      htmlStr,
      'text/html'
    ).body;

    // get elements
    response.firstElem = $(':scope > *:first-child', responseBodyElem);
    response.resultsInfoElem = $(
      ':scope > [data-container="load-more-btn"]',
      responseBodyElem
    );

    response.resultsInfoElem &&
      (response.loadMoreBtnElem = $(
        ':scope > button.load-more-button',
        response.resultsInfoElem
      ));

    return response;
  };

  _showLoadingAnim = () => {
    this.mCalendarResultsElem.style.marginBottom = '400px';
    this.mLoadingAnimation.show();
  };

  _hideLoadingAnim = () => {
    this.mCalendarResultsElem.style.marginBottom = '';
    this.mLoadingAnimation.hide();
  };

  shuffleArray = array => {
    function shuffle(arra1) {
      let ctr = arra1.length;
      let temp;
      let index;

      // While there are elements in the array
      while (ctr > 0) {
        // Pick a random index
        index = Math.floor(Math.random() * ctr);
        // Decrease ctr by 1
        ctr--;
        // And swap the last element with it
        temp = arra1[ctr];
        arra1[ctr] = arra1[index];
        arra1[index] = temp;
      }
      return arra1;
    }
    return shuffle(array);
  };

  _setHitList = inElem => {
    const type = this.mCalendarResultsElem.dataset.type;
    const existingElem = $(
      ':scope .items, :scope .list',
      this.mCalendarResultsElem
    );

    if (existingElem) {
      const itemsArray = $$('.item, .list__item', inElem);

      if (itemsArray.length) {
        // only shuffle items for courses list, not for normal calendar list
        const items =
          type === 'courses' ? this.shuffleArray(itemsArray) : itemsArray;

        items.forEach(item => {
          existingElem.appendChild(item);
        });
      }
    } else {
      this.mCalendarResultsElem.insertAdjacentHTML(
        'afterbegin',
        inElem.outerHTML
      );

      // shuffle items if its the courses list, not the calendar list
      const items = $$('.item, .list__item', this.mCalendarResultsElem);
      if (type === 'courses' && items.length) {
        const randomizeItems = this.shuffleArray(items);

        // remove old items
        items.forEach(item => remove(item));

        // adding back items, but randomized
        randomizeItems.forEach(item => {
          $(
            ':scope .items, :scope .list',
            this.mCalendarResultsElem
          ).insertAdjacentHTML('afterbegin', item.outerHTML);
        });
      }
    }
  };

  _deleteHitList = () => {
    if (!this.mCalendarResultsElem) return;

    const elem = this.mCalendarResultsElem;

    // delete everything except the load-more-button and the animation element
    $$(
      ':scope > *:not([data-container="load-more-btn"]):not(.loading-animation)',
      elem
    ).forEach(childElem => {
      elem.removeChild(childElem);
    });
  };

  _setLoadMoreBtn = (resultsInfoElem, loadMoreBtn) => {
    const that = this;
    this.mCalendarResultsElem.appendChild(resultsInfoElem);
    loadMoreBtn.addEventListener('click', function cb(e) {
      // event listener should be triggered once
      e.currentTarget.removeEventListener('click', cb);
      // order ResultsFetcher to load more results
      const url = loadMoreBtn.dataset.uri;
      that._dispatchEvent(that.mCalendarElem, ResultsFetcher.EventIn.GET, url);
    });
  };

  _deleteLoadMoreBtn = () => {
    if (!this.mCalendarResultsElem) return;

    const elem = this.mCalendarResultsElem;

    $$(':scope > [data-container="load-more-btn"]', elem).forEach(childElem => {
      elem.removeChild(childElem);
    });
  };

  _setResultsInfo = resultsInfoElem => {
    if (!resultsInfoElem.dataset.resultsCount) {
      return;
    }

    const title = this.mTranslation['results-counter-title'];
    const info = resultsInfoElem.dataset.resultsCount + ` ${title || ''}`;
    this._dispatchEvent(
      this.mCalendarFilterElem,
      CalendarFilter.EventIn.UPDATE_RESULTS_INFO,
      info
    );
  };

  _clearResultsInfo = () => {
    this._dispatchEvent(
      this.mCalendarFilterElem,
      CalendarFilter.EventIn.UPDATE_RESULTS_INFO,
      ''
    );
  };

  _dispatchEvent = (channelElem, eventName, data) => {
    if (!channelElem || !eventName) return;

    const event = new CustomEvent(eventName, { detail: data });
    channelElem.dispatchEvent(event);
  };
}

export default ResultsLoader;
