import debounce from 'lodash.debounce';
import { $, $$, isImageLoaded, addStyles } from 'utils/dom';
import { breakpoints } from 'utils/breakpoints';
const objectFitImages = require('object-fit-images');

// register custom filters for nunjucks templates
// module.exports = {
//   filters: {
//     lorem: count => {
//       return faker.lorem.words(count || 10);
//     },
//   },
// };

// const nunjucks = require('nunjucks');
// const env = new nunjucks.Environment();
// const faker = require('faker');

// env.addFilter('lorem', (faker, count) => {
//   return faker.lorem.words(count || 10);
// });

export class Terminal {
  BREAKPOINT = 768;

  constructor(el) {
    this.el = el;
    this.items = $$('[data-item]', this.el);
    this.dragscroll = $('[data-dragscroll]', this.el);
    this.nav = $('[data-nav]', this.el);
    this.navLinks = $$('[data-nav-link]', this.el);
    this.controlButtons = $$('[data-control]', this.el);
    this.display = $('[data-display]', this.el);
    this.caption = {
      el: $('[data-caption]', this.display),
      content: $('[data-caption-content]', this.display),
      leadIcon: $('[data-lead-icon]', this.display),
      heading: $('[data-caption-heading]', this.display),
      header: $('[data-caption-header]', this.display),
      body: $('[data-caption-body]', this.display),
    };
    this.visibleItems = [];

    // state
    this.state = {
      isLoaded: false,
      selectedItem: Math.floor(Math.random() * this.items.length), // select random item on start
    };

    // register event handlers
    window.addEventListener(
      'resize',
      debounce(() => {
        this._updateVerticalScroll();
        this.scrollTo(this.state.selectedItem);
        this.caption.el.style.height = this.caption.body.offsetHeight + 'px';
        this.updateControlStatus();
      }, 300)
    );

    this.dragscroll &&
      this.dragscroll.addEventListener('scroll', () => {
        this.updateControlStatus();
      });

    this.navLinks.forEach((navLink, i) => {
      navLink.addEventListener('click', event => {
        event.preventDefault();
        this.selectItem(i, false);
        this.updateControlStatus();
      });
    });

    // init
    this._updateVerticalScroll();
    this.selectItem(this.state.selectedItem, true);
    this.updateControlStatus();
    this.controlButtons.forEach(button => {
      button.addEventListener('click', () => this.slideScrollContainer(button));
    });
  }

  _updateVerticalScroll = () => {
    if (window.innerWidth > breakpoints.Width.MOBILE) {
      this._enableVerticalScroll();
    } else {
      this._disableVerticalScroll();
    }
  };

  _enableVerticalScroll = () => {
    // hide scrollbar
    this.dragscroll &&
      addStyles(this.dragscroll, {
        left: '0',
        width: 'calc(100% + 200px)',
        overflowX: 'hidden',
        overflowY: 'auto',
      });

    this.nav &&
      addStyles(this.nav, {
        left: '0',
        transform: 'translateX(-100px)',
      });
  };

  _disableVerticalScroll = () => {
    this.dragscroll && this.dragscroll.setAttribute('style', '');
    this.nav && this.nav.setAttribute('style', '');
  };

  scrollTo(i) {
    if (this.dragscroll && this.navLinks.length) {
      if (window.innerWidth > this.BREAKPOINT) {
        this.dragscroll.scrollTop = this.navLinks[i].offsetTop;
      } else {
        this.dragscroll.scrollLeft = this.navLinks[i].offsetLeft - 10;
      }
    }
  }

  async selectItem(i, jumpToAnchor) {
    if (this.state.isLoaded && i === this.state.selectedItem) return;

    // fade caption content out, replace content and fade back in
    const item = this.items[i];
    this.replaceCaptionContent(item, { hasFade: this.state.isLoaded });

    // remove the link reference
    const link = $('[data-link]', this.display);
    link && link.removeAttribute('href');

    // fade image out, replace source and fade back in
    const picture = $('[data-picture]', this.display);
    const newPicture = await replacePicture(picture, item.dataset);

    // add the link reference
    link && item.dataset.link && link.setAttribute('href', item.dataset.link);

    setTimeout(() => {
      objectFitImages(newPicture); // for IE 11
    }, 0);

    // set state to loaded
    if (!this.state.isLoaded) {
      this.state.isLoaded = true;
      this.el.classList.add('is-loaded');
    }

    if (this.nav) {
      // mark item nav link as selected
      this.navLinks[this.state.selectedItem].classList.remove('is-active');
      this.navLinks[i].classList.add('is-active');

      // jump to active nav-link (only on load, not on click)
      jumpToAnchor && this.scrollTo(i);
    }

    // update state
    this.state.selectedItem = i;
  }

  replaceCaptionContent(item, { hasFade }) {
    const setContent = () => {
      if (item.dataset.icon) {
        this.caption.leadIcon.className = `icon-${item.dataset.icon} lead-icon`;
      } else if (item.dataset.boxColor) {
        this.caption.leadIcon.className = `nav-box bg-${item.dataset.boxColor} lead-icon`;
      }
      this.caption.heading.innerText = $(
        '[data-caption-heading]',
        item
      ).innerText;
      this.caption.body.innerHTML = $('[data-caption-body]', item).innerHTML;
      this.caption.el.style.height = this.caption.body.offsetHeight + 'px';
    };

    const onFadedOut = () => {
      this.caption.content.classList.remove('is-faded');
      this.caption.content.removeEventListener('transitionend', onFadedOut);
      // change caption content
      setContent();
    };

    if (hasFade) {
      this.caption.content.classList.add('is-faded');
      this.caption.content.addEventListener('transitionend', onFadedOut);
    } else {
      setContent();
    }
  }

  // fakeText = count => {
  //   return faker.lorem.words(count || 10);
  // };

  getVisibleNavLinks = () => {
    const { top, bottom, right, left } =
      this.dragscroll.getBoundingClientRect();
    const containerTop = top;
    const containerBottom = bottom;
    const containerRight = right;
    const containerLeft = left;
    this.visibleItems = [];

    this.navLinks.forEach((navItem, index) => {
      const { top, bottom, right, left } = navItem.getBoundingClientRect();

      if (window.innerWidth > this.BREAKPOINT) {
        if (
          (top > containerTop || bottom > containerTop) &&
          top + 5 < containerBottom
        ) {
          this.visibleItems.push(index);
        }
      } else {
        if (
          (left > containerLeft || right > containerLeft) &&
          left + 5 < containerRight
        ) {
          this.visibleItems.push(index);
        }
      }
    });
  };

  slideScrollContainer = button => {
    const direction = button.dataset.control;
    const targetLink =
      direction === 'next'
        ? this.navLinks[this.visibleItems[1]]
        : this.navLinks[this.visibleItems[0] - 1];

    if (targetLink) {
      if (window.innerWidth > this.BREAKPOINT) {
        this.scrollToSlide(
          this.dragscroll,
          targetLink.offsetTop - 10,
          200,
          () => {
            this.updateControlStatus();
          }
        );
      } else {
        this.scrollToSlide(
          this.dragscroll,
          targetLink.offsetLeft - 10,
          200,
          () => {
            this.updateControlStatus();
          }
        );
      }
    }
  };

  updateControlStatus = () => {
    if (this.dragscroll) {
      this.getVisibleNavLinks();

      this.controlButtons.map(button => button.removeAttribute('disabled'));
      if (this.visibleItems[0] === 0) {
        $('.terminal-control-btn.prev').setAttribute('disabled', 'true');
      }
      if (this.visibleItems.slice(-1)[0] === this.navLinks.length - 1) {
        $('.terminal-control-btn.next').setAttribute('disabled', 'true');
      }
    }
  };

  scrollToSlide = (target, to = 0, duration = 1000, scrollToDone = null) => {
    Math.easeInOutQuad = (time, begin, change, dur) => {
      let t = time;
      const b = begin;
      const c = change;
      const d = dur;

      t /= d / 2;
      // eslint-disable-next-line no-mixed-operators
      if (t < 1) return (c / 2) * t * t + b;
      t -= 1;
      // eslint-disable-next-line no-mixed-operators
      return (-c / 2) * (t * (t - 2) - 1) + b;
    };

    const start =
      window.innerWidth > this.BREAKPOINT
        ? target.scrollTop
        : target.scrollLeft;
    const change = to - start;
    const increment = 20;
    let currentTime = 0;

    const animateScroll = () => {
      currentTime += increment;

      if (window.innerWidth > this.BREAKPOINT) {
        target.scrollTop = Math.easeInOutQuad(
          currentTime,
          start,
          change,
          duration
        );
      } else {
        target.scrollLeft = Math.easeInOutQuad(
          currentTime,
          start,
          change,
          duration
        );
      }

      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      } else if (scrollToDone) scrollToDone();
    };

    animateScroll();
  };
}

function replacePicture(pic, data) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async resolve => {
    pic.classList.add('is-faded');

    const newPic = pic.cloneNode(true);
    const imgDesktop = $('[data-img-desktop]', newPic);
    const sourceLaptop = $('[data-img-laptop]', newPic);
    const sourceTablet = $('[data-img-tablet]', newPic);
    const sourceMobile = $('[data-img-mobile]', newPic);
    if (imgDesktop) imgDesktop.src = data.imgDesktop;
    if (sourceLaptop) sourceLaptop.srcset = data.imgLaptop;
    if (sourceTablet) sourceTablet.srcset = data.imgTablet;
    if (sourceMobile) sourceMobile.srcset = data.imgMobile;
    newPic.classList.add('is-overlay');
    pic.parentNode.insertBefore(newPic, pic);

    const onFadedOut = () => {
      newPic.classList.remove('is-overlay');
      pic.parentNode.removeChild(pic);
    };

    pic.addEventListener('transitionend', onFadedOut);

    await isImageLoaded(imgDesktop);
    newPic.classList.remove('is-faded');
    resolve(newPic);
  });
}

const moveFocusButton = $$('[data-move-focus]');
if (moveFocusButton.length) {
  const getFocusElement = () => {
    const focusTarget = $('.terminal-display [data-receive-focus]');
    return focusTarget.focus();
  };
  const moveFocusFromButtons = () => {
    for (let i = 0; i < moveFocusButton.length; i++) {
      const buttonsArray = moveFocusButton[i];
      buttonsArray.addEventListener('click', function () {
        setTimeout(getFocusElement, 2000);
      });
    }
  };
  moveFocusFromButtons();
}
