const DEFAULT_CONFIG = {
  rootMargin: '0px',
  threshold: 0,
};

const isAbove = entry => entry.boundingClientRect.y < entry.rootBounds.y;

export class LazerLoader {
  constructor(options) {
    this.targets = options.targets;
    this.once = typeof options.once !== 'undefined' ? options.once : true;
    this.config = Object.assign({}, DEFAULT_CONFIG, options.config);
    this.cb = options.cb;
  }

  onIntersection = entries => {
    // Loop through the entries
    entries.forEach(entry => {
      // Are we in viewport?
      if (entry.intersectionRatio > 0) {
        // Do stuff
        this.once && this.observer.unobserve(entry.target);
        this.handleIntersection(entry);
      }
    });
  };

  handleIntersection = entry => {
    this.cb(entry, isAbove(entry));
  };

  destroy = () => {
    this.targets.forEach(target => {
      this.observer.unobserve(target);
    });
  };

  init = () => {
    this.observer = new IntersectionObserver(this.onIntersection, this.config);
    this.targets.forEach(target => {
      this.observer.observe(target);
    });
  };
}

export class PictureLazerLoader extends LazerLoader {
  constructor(props) {
    super(props);

    this.once = true;
  }

  handleIntersection = entry => {
    const { target } = entry;
    const nodes = [...target.childNodes];

    nodes.forEach(node => {
      if (node.nodeName === 'SOURCE') {
        node.setAttribute('srcset', node.dataset.srcset);
        node.removeAttribute('data-srcset');
      }
      if (node.nodeName === 'IMG') {
        node.setAttribute('src', node.dataset.src);
        node.removeAttribute('data-src');
      }
    });

    this.cb(entry, isAbove(entry));
  };
}

export class VideoLazerLoader extends LazerLoader {
  constructor(props) {
    super(props);

    this.once = true;
  }

  handleIntersection = entry => {
    const { target } = entry;
    const nodes = [...target.childNodes];

    if (target.nodeName !== 'VIDEO') return;

    nodes.forEach(node => {
      if (typeof node.tagName === 'string' && node.nodeName === 'SOURCE') {
        node.setAttribute('src', node.dataset.src);
        node.removeAttribute('data-src');
      }
    });

    target.load();

    this.cb(entry, isAbove(entry));
  };
}

export class APILazerLoader extends LazerLoader {
  constructor(props) {
    super(props);

    this.loadAPI = props.loadAPI;
    this.queue = [];
    this.api = null;
    this.apiState = 'not loaded';
  }

  handleIntersection = entry => {
    if (this.apiState === 'loaded') {
      this.cb(this.api, entry);
    } else if (this.apiState === 'fetching') {
      this.queue.push(entry);
    } else if (this.apiState === 'not loaded') {
      this.apiState = 'fetching';
      this.queue.push(entry);
      this.loadAPI().then(api => {
        this.api = api;
        this.apiState = 'loaded';
        this.queue.forEach(entry => {
          this.cb(this.api, entry);
        });
        this.queue.length = 0;
      });
    }
  };
}
