import { addListener, getGlobalOffset, $, addStyles, closest } from 'utils/dom';
import * as Translation from 'utils/translation';
import TinyDatePicker from './tiny-date-picker';
import DropdownMenu from '../_dropdown-menu/dropdown-menu';
import { CalendarFilter } from '../calendar-filter';
import { strToDate } from '../_helper';

const defaultOpt = {
  dateGroupElem: null,
  eventScopeElem: document.documentElement,
};

class DatePicker {
  static Event = {
    CLOSE_ALL: 'cf-1.date-picker.close-all',
  };

  constructor(options) {
    const opt = Object.assign(defaultOpt, options);

    this.mEventScopeElem = opt.eventScopeElem;
    this.mDateGroupElem = opt.dateGroupElem;
    this.mInputElem = $('input[data-js*="date-"]', this.mDateGroupElem);
    this.mBtnElem = $('button[data-js*="date-btn"]', this.mDateGroupElem);

    this.mDropdownMenuElem = closest(this.mDateGroupElem, '.ddm-1');
    this.mDatePickerContainerElem = $(
      '.cf-1__date-picker-container',
      this.mDropdownMenuElem
    );
    this.mCaptionElem = $('.cf-1__date-group-caption', this.mDateGroupElem);

    const datePickerOptions = {
      mode: 'dp-below',
      appendTo: this.mDatePickerContainerElem,
      min: this._formatDate(new Date()),
      max: '31.12.2037',
      dayOffset: 1,
      format: this._formatDate,
      parse: this._parseDate,
      lang: Translation.getKey('calendar-filter', 'datepicker'),
    };

    this.mTinyDatePicker = TinyDatePicker(this.mInputElem, datePickerOptions);
    this.mDeviceType = null;

    this.mEventScopeElem && this._events();
  }

  _events = () => {
    const that = this;
    this.mTinyDatePicker &&
      this.mTinyDatePicker.on({
        open: () => {
          const dpCurrentElem = $('.dp-current', that.mDatePickerContainerElem);
          dpCurrentElem && dpCurrentElem.focus();
        },
      });

    // open or close the date picker with the button
    this.mBtnElem &&
      addListener(this.mBtnElem, 'click', () => {
        const dpIsOpened = this.mDatePickerContainerElem.hasChildNodes();
        if (dpIsOpened) {
          this.mTinyDatePicker.close();
        } else {
          this.mTinyDatePicker.open();
        }
      });

    // close date picker if window size changes
    addListener(window, 'resize', this.mTinyDatePicker.close);

    // close date picker if it host (dropdown menu) is closed
    addListener(this.mEventScopeElem, DropdownMenu.Event.IS_CLOSED, e => {
      const dropdownMenuElem = e.detail.dropdownMenuElem;
      dropdownMenuElem.contains(this.mInputElem) &&
        this.mTinyDatePicker.close();
    });

    // close date picker by receiving the event CLOSE_ALL
    addListener(this.mEventScopeElem, DatePicker.Event.CLOSE_ALL, () => {
      this.mTinyDatePicker.close();
    });

    // update calendar position
    this.mTinyDatePicker.mode.adjustPosition = this._updateGeometry;

    // detect if the device type has changed
    addListener(
      this.mEventScopeElem,
      CalendarFilter.Event.DEVICE_TYPE_CHANGED,
      e => (this.mDeviceType = e.detail.deviceType)
    );
  };

  _updateGeometry = () => {
    const offset = getGlobalOffset(this.mCaptionElem, this.mDropdownMenuElem);
    const top = offset.top + offset.height + 10;
    let left = offset.left;
    let dpWidth = 300;

    if (this.mDeviceType === CalendarFilter.DeviceType.MOBILE) {
      dpWidth = document.documentElement.offsetWidth - left * 2 + 4; // added border width of 2px + 2px
      left = 0;
    }

    addStyles(this.mDatePickerContainerElem, {
      top: `${top}px`,
      left: `${left}px`,
      width: `${dpWidth}px`,
    });
  };

  _formatDate = date => {
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();

    return `${day}.${month}.${year}`;
  };

  // date can be a string or a date object
  _parseDate = date => {
    const retDate = new Date();

    if (typeof date === 'string') {
      return strToDate(date);
    } else if (typeof date.getMonth === 'function') {
      return new Date(date);
    }

    return retDate;
  };
}

export default DatePicker;
