// feature tests

import {
  areClipPathShapesSupported,
  isPositionStickySupported,
} from 'utils/feature-tests';

// vendor
import 'dom-slider';
import 'element-qsa-scope';

// setup date-and-time language
import dateAndTime from 'date-and-time';
import 'date-and-time/locale/de';
import { getLanguage } from 'utils/translation';

// imports
import { bootstrap } from './bootstrap';
import { StickyHeader } from './elements/global-header/sticky-header';
import { MobileNav } from './elements/global-header/mobile-nav';
import { Menu } from './elements/global-header/menu';
import { TeaserInfo } from './elements/teaser/info/info';
import { ImageWithCaption } from './elements/image-with-caption/image-with-caption';
import { Terminal } from './elements/terminal/terminal';
import { TeaserGrid } from './elements/teaser/grid/grid';
import { SlideInHeading } from './elements/slide-in-heading/slide-in-heading';
import { Highlights } from './elements/highlights/highlights';
import { Calendar } from './elements/calendar/calendar';
import { CalendarFilter } from './elements/calendar-filter/calendar-filter';
import { TeaserHighlightsSlider } from './elements/teaser/highlights-slider/highlights-slider';
import { MonthObjectSlider } from './elements/teaser/month-object-slider/month-object-slider';
import { CalendarTeaser } from './elements/calendar-teaser/calendar-teaser';
import { ScrollTopBtn } from './elements/scrolltop-btn/scrolltop-btn';
import { SearchFilter } from './elements/search-filter/search-filter';
import { Accordion } from './elements/accordion/accordion';
import { CalendarDetails } from './elements/calendar-details/calendar-details';

import * as Gallery from './elements/gallery/gallery';
import * as Cinemagraph from './elements/media/cinemagraph/cinemagraph';
import * as Player from './elements/media/player/player';
import * as Cookiebanner from './elements/cookiebanner/cookiebanner';

import * as TeaserList from './elements/teaser/list/list';
import * as LoadMoreButton from './elements/load-more-btn/load-more-btn';
import * as PageHeaderSlider from './elements/page-header-slider/page-header-slider';

window.addEventListener('load', () => {
  bootstrap([
    ['.header', StickyHeader],
    ['.nav', MobileNav],
    ['.menu', Menu],
    ['.teaser-info', TeaserInfo],
    ['.image-with-caption', ImageWithCaption],
    ['.terminal', Terminal],
    ['.teaser .teaser-grid', TeaserGrid],
    ['.teaser .teaser-exhibition-grid', TeaserGrid],
    ['.teaser .teaser-month-object-grid', TeaserGrid],
    ['.slide-in-heading', SlideInHeading],
    ['.highlights', Highlights],
    ['.calendar', Calendar],
    ['.calendar-filter', CalendarFilter],
    ['.teaser-highlights-slider .teaser-grid', TeaserHighlightsSlider],
    ['.teaser .teaser-month-object-slider', MonthObjectSlider],
    ['.calendar-teaser', CalendarTeaser],
    ['.scrolltop-btn', ScrollTopBtn],
    ['.search-filter', SearchFilter],
    ['.accordion', Accordion],
    ['.calendar-details', CalendarDetails],
  ]);
});

export {
  // default,
  Menu,
  MobileNav,
  TeaserInfo,
  ImageWithCaption,
  Terminal,
  SlideInHeading,
  Gallery,
  Cinemagraph,
  Player,
  Cookiebanner,
  StickyHeader,
  TeaserGrid,
  Highlights,
  Calendar,
  CalendarFilter,
  TeaserHighlightsSlider,
  CalendarTeaser,
  ScrollTopBtn,
  MonthObjectSlider,
  SearchFilter,
  Accordion,
  CalendarDetails,
  TeaserList,
  LoadMoreButton,
  PageHeaderSlider,
};

// set language in date-and-time module
dateAndTime.locale(getLanguage());

if (areClipPathShapesSupported()) {
  document.body.classList.add('supports-clip-path-shapes');
}

if (isPositionStickySupported()) {
  document.body.classList.add('support-position-sticky');
} else {
  document.body.classList.add('no-support-position-sticky');
}
