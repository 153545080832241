const bp = {
  Width: {
    XS_MOBILE: 600,
    MOBILE: 768,
    XS_TABLET: 992,
    TABLET: 1024,
    DESKTOP: 1380,
    MAX_WIDTH: 1440,
  },
};

const updateBreakpoints = () => {
  bp.isMobileMenuMax = window.innerWidth <= bp.Width.DESKTOP;
  bp.isTabletMax = window.innerWidth <= bp.Width.TABLET;
};

updateBreakpoints();

window.addEventListener('resize', updateBreakpoints);

export { bp as breakpoints };
