class ResultsFetcher {
  constructor(
    options = {
      onLoaded: null,
      onCanceled: null,
      onError: null,
    }
  ) {
    this.mOnLoaded = options.onLoaded;
    this.mOnCanceled = options.onCanceled;
    this.mOnError = options.onError;

    this.mCancelFunc = null;
  }

  cancel = () => {
    this.mCancelFunc && this.mCancelFunc();
  };

  fetch = url => {
    const that = this;

    import(/* webpackChunkName: 'axios' */ 'axios').then(_ => {
      const axios = _.default;

      // cancel the running request
      that.mCancelFunc && that.mCancelFunc();

      axios
        .get(url, {
          cancelToken: new axios.CancelToken(function executor(c) {
            that.mCancelFunc = c;
          }),
        })
        .then(({ data }) => {
          that.mOnLoaded && that.mOnLoaded(data);
        })
        .catch(function (thrown) {
          if (axios.isCancel(thrown)) {
            that.mOnCanceled && that.mOnCanceled();
          } else {
            that.mOnError && that.mOnError(thrown);
          }
        });
    });
  };
}

export default ResultsFetcher;
