import DateGroup from './_date-group/date-group';
import CheckboxGroup from './_checkbox-group/checkbox-group';
import UsedFilterCheckbox from './_used-filters/used-filter-checkbox';
import UsedFilterDate from './_used-filters/used-filter-date';

class FiltersChangeNotifier {
  static Event = {
    FILTERS_CHANGED: 'FiltersChangedNotifier.Event.FILTERS_CHANGED',
  };

  constructor(calendarFilterElem) {
    this.mCalendarFilterElem = calendarFilterElem;
    this.mFilters = [];
  }

  init = () => {
    if (!this.mCalendarFilterElem) return;

    // register event listeners
    this._events();

    // notify that no filters are set yet
    this._dispatchFilterUpdatedEvent();
  };

  _events = () => {
    // listen to changes in checkboxes and the "used-filters" section (when checkbox filters are removed)
    [
      CheckboxGroup.Event.IS_CHANGED,
      UsedFilterCheckbox.Event.IS_REMOVED,
    ].forEach(eventName => {
      this.mCalendarFilterElem.addEventListener(eventName, e => {
        const isUpdated = this._updateCheckboxFilters(e.detail);

        isUpdated && this._dispatchFilterUpdatedEvent();
      });
    });

    // listen to changes in date input fields
    this.mCalendarFilterElem.addEventListener(DateGroup.Event.IS_CHANGED, e => {
      let isUpdated = this._updateDateFromFilter(e.detail.dateFromElem.value);
      isUpdated =
        this._updateDateToFilter(e.detail.dateToElem.value) || isUpdated;

      isUpdated && this._dispatchFilterUpdatedEvent();
    });

    // listen to changes in the "used-filters" section (when the date filter is removed)
    this.mCalendarFilterElem.addEventListener(
      UsedFilterDate.Event.IS_REMOVED,
      () => {
        let isUpdated = this._updateDateFromFilter('');
        isUpdated = this._updateDateToFilter('') || isUpdated;

        isUpdated && this._dispatchFilterUpdatedEvent();
      }
    );
  };

  _updateCheckboxFilters = data => {
    let isUpdated = false;

    const foundDataArr = this.mFilters.filter(
      f => f.name === data.name && f.value === data.value
    );
    const foundData = foundDataArr.length === 1 ? foundDataArr[0] : null;

    // add new data to filters
    if (!foundData && data.checked) {
      this.mFilters = [...this.mFilters, data];
      isUpdated = true;
    }
    // data exists in filters
    else if (foundData) {
      const i = this.mFilters.indexOf(foundData);
      // update data in filters
      if (data.checked) {
        this.mFilters = this._replaceDataInArr(this.mFilters, i, data);
        isUpdated = true;
      }
      // remove data from filters
      else {
        this.mFilters = this._removeDataFromArr(this.mFilters, i);
        isUpdated = true;
      }
    }

    return isUpdated;
  };

  _updateDateFromFilter = dateFrom => {
    let isUpdated = false;

    const dateFromArr = this.mFilters.filter(f => f.dateFrom);

    // add new data to filters
    if (dateFromArr.length === 0 && dateFrom) {
      this.mFilters = [...this.mFilters, { dateFrom }];
      isUpdated = true;
    }
    // data exists in filters
    else if (dateFromArr.length === 1) {
      const i = this.mFilters.indexOf(dateFromArr[0]);
      // update data in filters
      if (dateFrom && dateFromArr[0] !== dateFrom) {
        this.mFilters = this._replaceDataInArr(this.mFilters, i, {
          dateFrom,
        });
        isUpdated = true;
      }
      // remove data from filters
      else {
        this.mFilters = this._removeDataFromArr(this.mFilters, i);
        isUpdated = true;
      }
    }

    return isUpdated;
  };

  _updateDateToFilter = dateTo => {
    let isUpdated = false;

    const dateToArr = this.mFilters.filter(f => f.dateTo);

    // add new data to filters
    if (dateToArr.length === 0 && dateTo) {
      this.mFilters = [...this.mFilters, { dateTo }];
      isUpdated = true;
    }
    // data exists in filters
    else if (dateToArr.length === 1) {
      const i = this.mFilters.indexOf(dateToArr[0]);
      // update data in filters
      if (dateTo && dateToArr[0] !== dateTo) {
        this.mFilters = this._replaceDataInArr(this.mFilters, i, {
          dateTo,
        });
        isUpdated = true;
      }
      // remove data from filters
      else {
        this.mFilters = this._removeDataFromArr(this.mFilters, i);
        isUpdated = true;
      }
    }

    return isUpdated;
  };

  _replaceDataInArr = (arr, atIndex, data) => {
    const subArrBefore = this.mFilters.slice(0, atIndex);
    const subArrAfter = this.mFilters.slice(atIndex + 1, arr.length);
    return [...subArrBefore, data, ...subArrAfter];
  };

  _removeDataFromArr = (arr, atIndex) => {
    const subArrBefore = this.mFilters.slice(0, atIndex);
    const subArrAfter = this.mFilters.slice(atIndex + 1, arr.length);
    return [...subArrBefore, ...subArrAfter];
  };

  _dispatchFilterUpdatedEvent = () => {
    const event = new CustomEvent(FiltersChangeNotifier.Event.FILTERS_CHANGED, {
      detail: this.mFilters,
    });
    this.mCalendarFilterElem.dispatchEvent(event);
  };
}

export default FiltersChangeNotifier;
