import { cookiebannerLabels as defaultLabels } from '@3pc/cookiebanner';

const isProduction = document.body.hasAttribute('data-production');
const siteId = document.body.getAttribute('data-trackingId');
const hideCookiebanner = document.body.hasAttribute('data-hide-cookiebanner');
const imprintLink =
  document.querySelector('[data-imprint-link]') &&
  JSON.parse(document.querySelector('[data-imprint-link]').dataset.imprintLink);
const privacyPolicyLink =
  document.querySelector('[data-privacy-policy-link]') &&
  JSON.parse(
    document.querySelector('[data-privacy-policy-link]').dataset
      .privacyPolicyLink
  );

const matomoSiteId = isProduction ? siteId : 11;
const matomoUrl = isProduction
  ? 'https://matomo.technikmuseum.berlin/'
  : 'https://qa.linux15.3pc.de/matomo/';
const cookiebannerLabels = {
  ...defaultLabels,
  acceptRequired: {
    de: 'Nur notwendige Cookies zulassen',
    en: 'Accept essential cookies only',
  },
  acceptTracking: {
    de: 'Auch Tracking-Cookies zulassen',
    en: 'Accept also tracking cookies',
  },
  status: {
    de: 'Ihre aktuelle Einstellung: ',
    en: 'Your current setting: ',
  },
  statusRequiredAccepted: {
    de: 'Nur notwendige Cookies zugelassen',
    en: 'Only essential cookies accepted',
  },
  statusTrackingAccepted: {
    de: 'Auch Tracking-Cookies zugelassen',
    en: 'Tracking cookies accepted',
  },
  imprint: {
    de: 'Impressum',
    en: 'Imprint',
  },
  privacyPolicy: {
    de: 'Datenschutz',
    en: 'Privacy policy',
  },
  mainPanelTitle: {
    de: 'Wir nutzen Cookies.',
    en: 'We use cookies.',
  },
  mainPanelText: {
    de: `<p>Um die Funktionalität und Weiterentwicklung unserer Website zu optimieren, verwenden wir Cookies. Detaillierte Informationen über den Einsatz von Cookies erhalten Sie <a href=${
      privacyPolicyLink ? privacyPolicyLink.url : '/'
    }>hier</a>.</p>`,
    en: `<p>We use cookies to make our website work better and to improve it in the future. <a href=${
      privacyPolicyLink ? privacyPolicyLink.url : '/'
    }>Click here</a> for more information about how we use cookies.</p>`,
  },
};

const consentTypes = [
  {
    name: {
      de: 'Technisch notwendige Cookies',
      en: 'Necessary Cookies',
    },
    text: {
      de: `<p>Erforderliche Cookies garantieren Kernfunktionen wie Sicherheit, Netzwerkverwaltung und Barrierefreiheit. Sie können diese deaktivieren, indem Sie Ihre Browsereinstellungen ändern. Dies kann sich jedoch auf die Funktionsweise der Website auswirken.</p>`,
      en: `<p>Necessary cookies guarantee the proper functioning of core features like security, network administration, and accessibility. You can deactivate them by changing the preferences on your browser. However, deactivating necessary cookies may impact how the website functions.</p>`,
    },
    type: 'required',
  },
  {
    name: {
      de: 'Statistik- und Analyse-Cookies',
      en: 'Statistics and analysis cookies',
    },
    text: {
      de: `<p>Text de || Matomo</p>`,
      en: `<p>Text en || Matomo</p>`,
    },
    type: 'matomo',
    trackingScripts: [
      {
        insertSelector: 'body',
        script:
          'var _paq = window._paq = window._paq || [];\n' +
          "_paq.push(['trackPageView']);\n" +
          "_paq.push(['enableLinkTracking']);\n" +
          '(function() {\n' +
          `var u="${matomoUrl}";\n` +
          "_paq.push(['setTrackerUrl', u+'matomo.php']);\n" +
          `_paq.push(['setSiteId', '${matomoSiteId}']);\n` +
          "var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];\n" +
          "g.type='text/javascript'; g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);\n" +
          '})();',
      },
    ],
  },
  {
    name: {
      de: 'Filme',
      en: 'Videos',
    },
    text: {
      de: '<p>Text de || Filme</p>',
      en: '<p>Text en || Videos</p>',
    },
    type: 'video',
  },
  {
    name: {
      de: 'Eingebettete Inhalte',
      en: 'Embedded Content',
    },
    text: {
      de: '<p>Text de || Eingebettete Inhalte</p>',
      en: '<p>Text en || embedded content</p>',
    },
    type: 'embed',
  },
];

export {
  hideCookiebanner,
  imprintLink,
  privacyPolicyLink,
  cookiebannerLabels,
  consentTypes,
};
