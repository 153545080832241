import { $ } from 'utils/dom';
import ResultsFetcher from 'modules/results-fetcher';
import { LoadingAnimation } from '../../loading-animation/loading-animation';

// animation of the white item
export class TeaserInfo {
  constructor(node) {
    this.node = node;
    this.target = $('.items-featured .item', this.node);
    this.itemsFeaturedElem = $('.items-featured', this.node);

    this.isSpectrum = node.dataset.layout === 'spectrum';

    this.loadingAnimation = null;
    this.itemsFeaturedEndpoint = null;

    this.init();
  }

  onIntersection = entry => {
    if (entry.intersectionRatio <= 0) return;

    entry.target.classList.add('slideleft');
    this.observer.unobserve(entry.target);
  };

  createObserver = entries =>
    new IntersectionObserver(entries => entries.forEach(this.onIntersection));

  init = () => {
    if (!this.itemsFeaturedElem) return;

    this.itemsFeaturedEndpoint = this.itemsFeaturedElem.dataset.endpoint;

    if (!this.target && this.itemsFeaturedEndpoint) {
      this._fetchDataFromServer();
    } else if (this.target) {
      this.observer = this.createObserver(this.target);
      this.observer.observe(this.target);
    }
  };

  _fetchDataFromServer = () => {
    this._showLoadingAnimation();

    // fetch data from the server
    const resultsFetcher = new ResultsFetcher({
      onLoaded: this._onDataFetched,
    });
    resultsFetcher.fetch(this.itemsFeaturedEndpoint);
  };

  _onDataFetched = data => {
    this._hideLoadingAnimation();

    // add data to dom
    if (!Array.isArray(data) || data.length !== 1) return;

    const htmlStr = data[0].html;

    const bodyElem = new DOMParser().parseFromString(htmlStr, 'text/html').body;
    let itemElem = $('.item', bodyElem);

    if (!itemElem) return;

    this.itemsFeaturedElem.insertAdjacentHTML('afterbegin', itemElem.outerHTML);

    // create the observer
    itemElem = $('.item', this.itemsFeaturedElem);
    this.observer = this.createObserver(itemElem);
    this.observer.observe(itemElem);
  };

  _showLoadingAnimation = () => {
    if (!this.loadingAnimation) {
      this.loadingAnimation = new LoadingAnimation(
        this.itemsFeaturedElem,
        this.isSpectrum,
        {
          position: 'absolute',
          top: 'calc(50% - 1em)',
          left: 'calc(50% - 0.5em)',
        }
      );
    }

    this.loadingAnimation.show();
    this.itemsFeaturedElem.style.minHeight = '250px';
  };

  _hideLoadingAnimation = () => {
    this.loadingAnimation && this.loadingAnimation.hide();
    this.itemsFeaturedElem.style.minHeight = '';
  };
}
