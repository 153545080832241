import { addListener, $ } from 'utils/dom';
import UsedFilterDate from '../_used-filters/used-filter-date';
import { strToDate } from '../_helper';

const defaultOpt = {
  dateGroupFromElem: null,
  dateGroupToElem: null,
  eventScopeElem: document.documentElement,
};

class DateGroup {
  static Event = {
    IS_CHANGED: 'cf-1.date-group.is-changed',
    UPDATE_STATE: 'cf-1.date-group.update-state',
  };

  constructor(options) {
    const opt = Object.assign(defaultOpt, options);

    this.mEventScopeElem = opt.eventScopeElem;
    this.mDateFromElem = $('input[data-js="date-from"]', opt.dateGroupFromElem);
    this.mDateToElem = $('input[data-js="date-to"]', opt.dateGroupToElem);

    this.mDateFromElem && this.mDateToElem && this._setDateElementsValue();
    this.mEventScopeElem && this._events();
  }

  _setDateElementsValue = () => {
    const dateRange = this._getDateRangeFromURI(window.location.search);
    this.mDateFromElem.value = dateRange ? dateRange[0] : '';
    this.mDateToElem.value = dateRange ? dateRange[1] : '';
  };

  _getDateRangeFromURI = queryString => {
    queryString = decodeURIComponent(queryString);
    const dateRangeString = queryString?.split(/dateRange:([\d-]+)/)[1];
    const dateRange = dateRangeString?.split('-').map(str => {
      if (!str) return null;

      const year = str.slice(0, 4);
      const month = str.slice(4, 6);
      const day = str.slice(6, 8);

      return `${day}.${month}.${year}`;
    });

    return dateRange;
  };

  _events = () => {
    // send custom IS_CHANGED event
    addListener(this.mDateFromElem, 'change', () => this._onDateChange(true));
    addListener(this.mDateToElem, 'change', () => this._onDateChange(false));

    // inform about the current state
    addListener(this.mEventScopeElem, DateGroup.Event.UPDATE_STATE, () =>
      this._sendChangedEvent()
    );

    // clear input fields if the date filter is removed from the list of used filters
    addListener(
      this.mEventScopeElem,
      UsedFilterDate.Event.IS_REMOVED,
      this._resetDateValues
    );
  };

  _resetDateValues = () => {
    this.mDateFromElem.value = '';
    this.mDateToElem.value = '';
  };

  _sendChangedEvent = () => {
    const event = new CustomEvent(DateGroup.Event.IS_CHANGED, {
      detail: {
        dateFromElem: this.mDateFromElem,
        dateToElem: this.mDateToElem,
      },
    });
    this.mEventScopeElem.dispatchEvent(event);
  };

  _onDateChange = isDateFrom => {
    // compare and correct dates
    if (this.mDateFromElem.value && this.mDateToElem.value) {
      const dateFrom = strToDate(this.mDateFromElem.value);
      const dateTo = strToDate(this.mDateToElem.value);

      if (dateTo.getTime() < dateFrom.getTime()) {
        isDateFrom && (this.mDateToElem.value = '');
        !isDateFrom && (this.mDateFromElem.value = '');
      }
    }

    this._sendChangedEvent();
  };
}

export default DateGroup;
