import throttle from 'lodash.throttle';
import { getScrollTop } from 'utils/scroll';
import { $ } from 'utils/dom';

export class StickyHeader {
  constructor(el) {
    this.el = el;
    this.navbar = $('.navbar', this.el);
    this.scrollThreshold = 200;

    this.onTransitionEndBind = this.onTransitionEnd.bind(this);

    window.addEventListener('scroll', throttle(this.onScroll.bind(this), 50));
  }

  onTransitionEnd() {
    this.navbar.classList.remove('is-transitioning');
    this.navbar.removeEventListener('transitionend', this.onTransitionEndBind);
  }

  onScroll() {
    const scrollTop = getScrollTop();
    if (
      scrollTop > this.scrollThreshold &&
      !this.el.classList.contains('is-sticky')
    ) {
      this.el.classList.add('is-sticky');
      this.navbar.classList.add('is-transitioning');
      this.navbar.addEventListener('transitionend', this.onTransitionEndBind);
    } else if (
      scrollTop <= this.scrollThreshold &&
      this.el.classList.contains('is-sticky')
    ) {
      this.el.classList.remove('is-sticky');
      this.navbar.classList.add('is-transitioning');
      this.navbar.addEventListener('transitionend', this.onTransitionEndBind);
    }
  }
}
